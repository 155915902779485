import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'leaflet/dist/leaflet.css';
import 'font-awesome/css/font-awesome.min.css';
import './assets/scss/realsite.scss';
const agCLogo = require("./assets/img/AgC_Logo.jpg");

if (!window.location.origin) {
    window.location.origin =
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "");
}

var baseUrl = 'https://auth.landdb.com'; //'process.env.REACT_APP_AUTH_BASE_URL'
const config = {
    baseUrl: baseUrl,
    issuer: 'https://auth.landdb.com/oauth2/auscm85nULtgvMzWV355', //process.env.REACT_APP_AUTH_ISSUER,
    redirect_uri: window.location.origin + '/implicit/callback',
    client_id: '0oa71hm2iVMeweyJv356', //process.env.REACT_APP_AUTH_CLIENT_ID,
    logo: agCLogo,
    onAuthRequired: onAuthRequired,
    idps: [
        { type: 'GOOGLE', id: '0oa5n5za77hDVeQUy356' },
        { type: 'MICROSOFT', id: '0oacjef8kdbT7S4p8355' }
    ]
}

function onAuthRequired({ history }) {
    history.push('/loginForm');
}

ReactDOM.render(
    <BrowserRouter>
        <Security {...config}>
            <App />
        </Security>
    </BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
