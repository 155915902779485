const shp = require("shpjs");
var JSZip = require("jszip");

const GeoJsonConvertor = async files => {
  let result = [];
  let shpBuffer;
  let dbfBuffer;

  if (files) {
    const Buffers = await GetBuffers(files);
    for (let index = 0; index < Buffers.length; index++) {
      const buffer = Buffers[index];
      try {
        if (files[index].name.slice(-4).toLowerCase() === ".shp")
          shpBuffer = buffer;
        else if (files[index].name.slice(-4).toLowerCase() === ".dbf")
          dbfBuffer = buffer;
        else if (files[index].name.slice(-4).toLowerCase() === ".zip") {
          const unzippedFiles = await UnZipFiles(buffer);
          shpBuffer = unzippedFiles.shp;
          dbfBuffer = unzippedFiles.dbf;
        }
      } catch (ex) {
        console.log(ex);
        result.push("");
      }
    }
  }

  // console.log("shape buffer:", shpBuffer);
  // console.log("dbf buffer:", dbfBuffer);
  if (shpBuffer && dbfBuffer) {
    try {
      const geojson = await shp.combine([
        shp.parseShp(shpBuffer),
        shp.parseDbf(dbfBuffer)
      ]);
      result.push(JSON.stringify(geojson));
    } catch (ex) {
      console.log(ex);
      result.push("");
    }
  }

  return result;
};

export default GeoJsonConvertor;

function GetBuffers(files) {
  return Promise.all(
    [].map.call(files, file => {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsArrayBuffer(file);
      });
    })
  ).then(results => {
    return results;
  });
}

async function UnZipFiles(content) {
  var new_zip = new JSZip();
  // more files !
  let files = {};
  await new_zip.loadAsync(content).then(async zip => {
    // you now have every files contained in the loaded zip
    for (var key in zip.files) {
      let file = zip.files[key];

      if (JSZip.support.uint8array) {
        await new_zip
          .file(file.name)
          .async("uint8array")
          .then(data => {
            // data is Uint8Array { 0=72, 1=101, 2=108, more...}
            if (file.name.slice(-4).toLowerCase() === ".shp") files.shp = data;
            else if (file.name.slice(-4).toLowerCase() === ".dbf")
              files.dbf = data;
          });
      }
    }
  });
  return files;
}

export function GetFeatures(geojson, crop, year) {
  let data = [];
  let counter = 0;
  geojson.features.forEach(feature => {
    //geometry of the polygon
    const geometry = feature.geometry;
    if (!geometry) return;

    let polygons = [];

    //check the type of the geometry
    if (geometry.type === "Polygon")
      polygons.push({
        type: geometry.type,
        coordinates: geometry.coordinates
      });
    else if (geometry.type === "MultiPolygon")
      polygons = ExtractPolygons(geometry);
    else throw Error("Error! The shape file contains invalid type.");

    //get the point coordinates for each polygon
    polygons.forEach(polygon => {
      //check the number of polygons that do not exceed 10,000 polygons
      if (counter > 10000)
        throw Error(
          "Error! The shape file exceeded the limited number of polygons, you can upload up to 10,000 polygons."
        );

      //increment the counter of the polygons
      counter++;

      //calculate the point coordinates from the polygon
      const point = GetPoint(polygon);

      //construct the geojson of the point
      const PointJson = {
        type: "Point",
        coordinates: point
      };

      //construct object and push it in the data
      data.push({
        polygon: polygon,
        crop_id: crop,
        crop_year: year,
        point: PointJson
      });
    });
  });
  return data;
}

//Function to extract the polygons from multipolygon
function ExtractPolygons(MultiPolygon) {
  let polygons = [];
  MultiPolygon.coordinates &&
    MultiPolygon.coordinates.forEach(coordinate => {
      const polygon = {
        type: "Polygon",
        coordinates: coordinate
      };
      // console.log(polygon);
      polygons.push(polygon);
    });
  return polygons;
}

function GetPoint(polygon) {
  const coordinates = polygon.coordinates[0];
  return (
    coordinates &&
    polygon.coordinates[0].reduce(
      (x, y) => {
        return [
          x[0] + y[0] / coordinates.length,
          x[1] + y[1] / coordinates.length
        ];
      },
      [0, 0]
    )
  );
}

// function JZBuffer(files){
//   fs.readFile("test.zip", function(err, data) {
//     if (err) throw err;
//     JSZip.loadAsync(data).then(function (zip) {
//         // ...
//     });
// });

// }

//console.log("Filled !");
//debugger;
// await shp(buffer).then(function(geojson) {
//   console.log(geojson);
//   result.push(JSON.stringify(geojson));
// });
