import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";

let counter = 0;
class FilesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: this.props.result,
      deleteArr: this.props.deleteArr
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.result !== this.props.result)
      this.setState({ result: this.props.result });

    if (prevProps.deleteArr !== this.props.deleteArr)
      this.setState({ deleteArr: this.props.deleteArr });
  }

  indexOfObject = (arr, object) => {
    return arr.findIndex(
      obj =>
        obj.crop_id === object.crop_id &&
        obj.crop_year === object.crop_year &&
        obj.partner === object.partner
    );
  };

  handleDelete = (e, object) => {
    const { deleteArr } = this.state;
    const formatedObj = {
      crop_id: object.crop_id,
      crop_year: object.crop_year,
      partner: object.partner
    };

    const index = this.indexOfObject(deleteArr, formatedObj);

    if (index < 0) deleteArr.push(formatedObj);
    else deleteArr.splice(index, 1);

    this.setState({ deleteArr });
  };

  render() {
    const { result, deleteArr } = this.state;
    return (
      <div className="table-responsive">
        <LoadingOverlay active={this.props.loading} spinner>
          <table className="table table-hover table-bordered">
            <thead>
              <tr>
                <th>Crop</th>
                <th className="text-center">Crop Year</th>
                <th className="text-center">Date</th>
                <th className="text-center">Partner</th>
                <th className="text-center">Tag</th>
              </tr>
            </thead>

            <tbody>
              {result && result.length > 0 ? (
                result.map(item => (
                  <tr key={counter++}>
                    <td>
                      <label>
                        <input
                          type="checkbox"
                          checked={this.indexOfObject(deleteArr, item) !== -1}
                          onChange={e => this.handleDelete(e, item)}
                        />{" "}
                        {item.crop_name}
                      </label>
                    </td>
                    <td className="text-center">{item.crop_year}</td>
                    <td className="text-center">{item.updated_at}</td>
                    <td className="text-center">{item.partner}</td>
                    <td className="text-center">
                      {item.tag !== "" ? item.tag : "N/A"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center no-data">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </LoadingOverlay>
      </div>
    );
  }
}

export default FilesTable;
