import { toast } from "react-toastify";

//Method for checking the extensions of files
export const checkExtensions = file => {
  const extension = getExtension(file);
  return extension === "shp" ||
    extension === "dbf" ||
    extension === "shx" ||
    extension === "zip"
    ? true
    : false;
};

//Method for checking the combination of extensions of files
export const checkCombinedExtensions = (files, option) => {
  if (option === "zipped") {
    let zippedCount = 0,
      unzippedCount = 0;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (getExtension(file) === "zip") zippedCount++;
      else unzippedCount++;
    }
    if (zippedCount > 0 && unzippedCount > 0) return false;
    return true;
  } else {
    let shpCount = 0,
      dbfCount = 0,
      shxCount = 0;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (getExtension(file) === "shp") shpCount++;
      else if (getExtension(file) === "dbf") dbfCount++;
      else shxCount++;
    }
    if (shpCount > 1 || dbfCount > 1 || shxCount > 1) return false;
    return true;
  }
};

//Method for extracting the extension from filename
export const getExtension = file => {
  return file.name.slice(-3).toLowerCase();
};

//check if the files with extensions .dbf, .shp are dropped together or not
export const checkShp_DbfFiles = files => {
  let shpCount = 0;
  let dbfCount = 0;
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    if (getExtension(file).toLowerCase() === "shp" && shpCount === 0)
      shpCount++;
    else if (getExtension(file).toLowerCase() === "dbf" && dbfCount === 0)
      dbfCount++;
  }
  // console.log(shpCount === dbfCount);
  if (
    (shpCount === dbfCount && shpCount > 0 && dbfCount > 0) ||
    getExtension(files[0]).toLowerCase() === "zip"
  )
    return true;

  return false;
};

//Friendly Error message for the user
export const toastError = (message, onHide) => {
  toast.error(message, {
    position: toast.POSITION.BOTTOM_LEFT
  });
  if (onHide) onHide();
};

export const toastSuccess = (message, onHide) => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_LEFT
  });
  if (onHide) onHide();
};

//Method to generate years
export const GenerateYears = startYear => {
  //check if the month is july then add a new year else remove that year
  var currentYear = new Date().getFullYear(),
    years = [];

  startYear = startYear || 2017;

  //Display max 10 years
  if (currentYear - startYear > 10)
    startYear = startYear + (currentYear - startYear - 10);
  else currentYear = new Date().getMonth() < 6 ? currentYear : currentYear + 1;

  while (startYear <= currentYear) {
    years.push({ value: startYear, label: startYear++, name: "year" });
  }
  return years;
};

//Method for extracting the crops in unified object to be used lately
export const ExtractCrops = crop_groups => {
  let groups = [];
  if (crop_groups) {
    crop_groups.forEach(crop => {
      const group = {
        id: crop.id,
        color: crop.color,
        name: crop.name,
        options: crop.crops
      };
      groups.push(group);
    });
    // groups.sort((a, b) => {
    //   var textA = a.name.toUpperCase();
    //   var textB = b.name.toUpperCase();
    //   return textA < textB ? -1 : textA > textB ? 1 : 0;
    // });
  }
  return groups;
};

export const ExtractPartners = partner_groups => {
  let groups = [];
  if (partner_groups) {
    partner_groups.forEach(partner => {
      groups.push({ value: partner, label: partner, name: "partner" });
    });
  }
  return groups;
};
