import React from "react";
import ShapeFileIcon from "./fileIcon";

const RenderFiles = ({ files, handleDelete, accpeted, message }) => {
  return files.map(file => (
    <ShapeFileIcon
      key={file.name}
      file={file}
      onDelete={handleDelete}
      accpeted={accpeted}
      message={message}
    />
  ));
};

export default RenderFiles;
