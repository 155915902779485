import React from "react";
//import ReactTooltip from "react-tooltip";
import FileIcon, { defaultStyles } from "react-file-icon";
import { getExtension } from "./../../services/uploadService";

const ShapeFileIcon = ({ file, accpeted, onDelete, message }) => {
  return (
    <div
      // data-tip
      // data-for={accpeted ? "success" : "error"}
      className="dz-preview dz-details focus"
    >
      <i
        onClick={() => onDelete(file)}
        className="fa fa-times-circle deleteBtn"
        aria-hidden="true"
      />
      <div className="center">
        {getExtension(file) === "zip" ? (
          <FileIcon
            extension={getExtension(file)}
            {...defaultStyles.rar}
            gradientColor="#2f5b9a"
            glyphColor="whitesmoke"
            foldColor="#2f5b9a"
            color="#8ca4c7"
            size={60}
          />
        ) : (
          <FileIcon
            extension={getExtension(file)}
            {...defaultStyles.docx}
            size={60}
          />
        )}
      </div>
      {/* 
      <ReactTooltip
        id={accpeted ? "success" : "error"}
        type={accpeted ? "success" : "error"}
      >
        <span>{message || "Converted to GeoJson Successfully"}</span>
      </ReactTooltip> */}
    </div>
  );
};

export default ShapeFileIcon;

// {/* <span className={accpeted ? "acceptedFile" : "rejectedFile"}>
//   {file.name}
// </span> */}
