export const getItem =(key)=>{
    return sessionStorage.getItem(key);
}


export const removeItem =(key)=>{
    return sessionStorage.removeItem(key);
}


export const addItem =(key,value)=>{
    return sessionStorage.setItem(key,value);
}

const clearStorage =()=>{
    return sessionStorage.clear();
}

export var storageService = {
    clearStorage,
    addItem,
    removeItem,
    getItem

};
