import React, { Component } from "react";
import SelectGroup from "./uploadComponents/selectGroup";
import "./uploadComponents/customeStyle.css";
import RenderFiles from "./uploadComponents/renderFiles";
import Input from "./uploadComponents/input";
import {
  checkExtensions,
  checkCombinedExtensions,
  getExtension,
  checkShp_DbfFiles,
  toastError,
  toastSuccess,
  GenerateYears,
  ExtractCrops
} from "../services/uploadService";
import { HttpPost_cropSpeciality } from "../services/APIsevices";
import Joi from "joi";
import FileUpload from "./uploadComponents/fileUpload";
import DefaultDropZone from "./uploadComponents/defaultDropzone";
import GeoJsonConvertor, { GetFeatures } from "./../services/geoJsonConvertor";

const initialState = {
  data: {
    accepted_Files: [],
    rejected_Files: [],
    converted_Files: [],
    partner: "",
    crop: "",
    year: "",
    tag: ""
  },
  loading: false,
  username: "",
  errors: [],
  showPartner: false
};

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  reset() {
    this.setState(initialState);
  }
  state = {
    data: {
      accepted_Files: [],
      rejected_Files: [],
      converted_Files: [],
      partner: "",
      crop: "",
      year: "",
      tag: ""
    },
    loading: false,
    username: "",
    crop_groups: [],
    partner_groups: [],
    errors: [],
    showPartner: false
  };

  schema = {
    partner: Joi.string()
      .required()
      .label("Partner"),
    crop: Joi.string()
      .required()
      .label("Crop"),
    year: Joi.number()
      .required()
      .label("Year"),
    accepted_Files: Joi.array(),
    rejected_Files: Joi.array(),
    converted_Files: Joi.array(),
    tag: Joi.string().allow("")
  };

  async componentDidMount() {
    if (
      this.props.cropGroups === undefined ||
      this.props.cropGroups.length === 0
    ) {
      // console.log("reloading crop groups in upload");
      await this.props.getCropGroups();
    }
    const crop_groups = this.props.cropGroups;
    // console.log("groups1: " + JSON.stringify(crop_groups));
    const groups = ExtractCrops(crop_groups);
    if (
      this.props.partners.data === undefined ||
      this.props.partners.data.length === 0
    ) {
      await this.props.getPartners();
    }
    const partner_groups = this.props.partners
      ? this.props.partners.data
      : null;
    // console.log(partner_groups);
    //const extractedPartners = ExtractPartners(partner_groups.data);
    //console.log(groups);
    this.setState({
      crop_groups: groups,
      partner_groups: partner_groups,
      username: this.props.user,
      showPartner: this.props.isAdmin
    });

    if (!this.props.isAdmin) {
      const data = this.state.data;
      data.partner = this.props.user;
      this.setState({ data });
    }
  }

  async componentDidUpdate(prevProp) {
    if (prevProp.isAdmin !== this.props.isAdmin) {
      this.setState({ showPartner: this.props.isAdmin });
    }
    if (prevProp.user !== this.props.user) {
      this.setState({ username: this.props.user });
    }
    if (!this.props.isVisible && prevProp.isVisible) {
      this.handleClose();
    }
    if (this.props.isVisible && !prevProp.isVisible) {
      // console.log("awaiting!!!");
      await this.props.getPartners();
      const partner_groups = this.props.partners.data;
      this.setState({ partner_groups });
      await this.updateState();
    }
  }

  onShow = () => {
    this.setState({ loading: true });
  };

  onHide = () => {
    this.setState({ loading: false });
  };

  validate = () => {
    const options = {
      abortEarly: false
    };
    const { error } = Joi.validate(this.state.data, this.schema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const subSchema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, subSchema);

    return error ? error.details[0].message : null;
  };

  handleChange = (input, name) => {
    input = input.target || input;
    const errors = { ...this.state.errors };
    let errorMessage;

    // console.log("change: " + input);
    const inputObj = {
      name: input.name || name,
      value: input.value || input
    };

    errorMessage = this.validateProperty(inputObj);

    // console.log("errorMessage: " + errorMessage);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name || name];

    const data = { ...this.state.data };

    data[input.name || "partner"] =
      input.value || (typeof input === "string" ? input : "");

    this.setState({ data, errors });
  };

  handleDrop = async (files, rejected) => {
    this.onShow();

    if (rejected.length > 0) {
      toastError(
        "Error! The uploaded File exceeded the maximum size, you can upload files up to 10 MB.",
        this.onHide
      );
      if (!files) return;
    }
    let { data } = this.state;
    let { accepted_Files, rejected_Files, converted_Files } = data;
    const frm_data = new FormData();
    let result;

    //validate number of dropped files
    if (
      files.length > 3 ||
      !checkCombinedExtensions([...accepted_Files, ...files], "unzipped")
    ) {
      toastError(
        "Error! You cannot upload more than one Shape file at once.",
        this.onHide
      );
      return;
    }

    //check for allowed extensions .zip, .shp, .dbf, .shx
    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      if (checkExtensions(file)) {
        const exist = accepted_Files.filter(f => f.name === file.name);
        if (exist.length > 0) continue;
        accepted_Files.push(file);
        frm_data.append("file", file);
      } else {
        const exist = rejected_Files.filter(f => f.name === file.name);
        if (exist.length > 0) continue;
        rejected_Files.push(file);
      }
    }

    //check for not allowed extensions
    if (rejected_Files.length > 0) {
      toastError(
        "Error! Cannot upload this file, Allowed extensions are *.shp, *.dbf, .shx or *.zip.",
        this.onHide
      );
      rejected_Files.splice(0, rejected_Files.length);
      return;
    }

    //check for a valid extension combinations
    if (!checkCombinedExtensions(accepted_Files, "zipped")) {
      toastError(
        "Error! You cannot upload zip file with other extensions.",
        this.onHide
      );
      accepted_Files.pop();
      return;
    }

    if (
      (accepted_Files.length > 1 && checkShp_DbfFiles(accepted_Files)) ||
      (accepted_Files.length === 1 && getExtension(accepted_Files[0]) === "zip")
    ) {
      converted_Files.pop();
      try {
        result = await GeoJsonConvertor(accepted_Files);
      } catch (ex) {
        toastError("Error! The uploaded Shape file is not valid.", this.onHide);
        return;
      }

      for (let index = 0; index < result.length; index++) {
        const gesojson = result[index];
        if (gesojson === "") {
          // if (accepted_Files.length > 1)
          toastError(
            "Error! The uploaded Shape file is not valid.",
            this.onHide
          );
        } else {
          converted_Files.push(gesojson);
        }
      }
    }
    this.setState({ data });
    this.onHide();
  };

  handleDelete = file => {
    // console.log("files:", file);

    const { data } = this.state;
    let { accepted_Files, rejected_Files, converted_Files } = data;
    let index = accepted_Files.indexOf(file);

    if (index > -1) {
      accepted_Files.splice(index, 1);
      // console.log("file", file.name);
      if (getExtension(file) !== "shx") converted_Files.pop();
    } else {
      index = rejected_Files.indexOf(file);
      rejected_Files.splice(index, 1);
    }
    this.setState({ data });
  };

  handleSubmit = async event => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (!this.props.isAdmin) {
      if (errors !== null) delete errors.partner;
      // console.log(errors);
    }

    if (errors) return;
    this.onShow();
    const { partner, crop, year, converted_Files, tag } = this.state.data;
    const { username } = this.state;

    //Add all the required data in the http request in postObject then Submit to the server
    if (converted_Files.length > 0) {
      try {
        const geojsonData = GetFeatures(
          JSON.parse(converted_Files[0]),
          crop,
          year
        );
        // console.log(geojsonData);
        let postObject = {
          geojson: geojsonData,
          partner: partner,
          crop_id: crop,
          crop_year: year,
          tag: tag,
          username: username || partner
        };
        // console.log(postObject);
        this.props.isLoading(true);
        const res = await HttpPost_cropSpeciality(postObject);
        // console.log("res of http: ", res);
        if (res === undefined) {
          toastError("Unexpected Error! Unable to upload file.", this.onHide);
        } else {
          // console.log("done", res);
          const groups = this.state.partner_groups && [
            ...this.state.partner_groups
          ];
          if (groups && groups.indexOf(partner) === -1) groups.push(partner);
          this.setState({ partner_groups: groups });
          toastSuccess("File Uploaded Successfully.", this.onHide);
          this.props.getPartners();
        }
      } catch (ex) {
        if (
          ex.message === "Error! The shape file contains invalid type." ||
          "Error! The shape file exceeded the limited number of polygons, you can upload up to 10,000 polygons."
        )
          toastError(ex.message, this.onHide);
        else
          toastError("Unexpected Error! Unable to upload file.", this.onHide);
        console.log(ex.message);
      }
    } else
      toastError(
        "Error! You must upload a valid shape File with extensions *.shp, *.dbf, .shx or *.zip.",
        this.onHide
      );
    this.props.isLoading(false);
    this.props.fileUploaded(year);
  };

  handleClose = async () => {
    // console.log("closing");
    let data = { ...this.state.data };
    data.accepted_Files.splice(0, data.accepted_Files.length);
    data.rejected_Files.splice(0, data.rejected_Files.length);
    data.converted_Files.splice(0, data.converted_Files.length);

    this.setState({ data });
    this.setState(initialState);
    this.props.onCloseClicked();
  };

  updateState = async () => {
    // console.log("updating");
    await this.props.getCropGroups();
    const crop_groups = this.props.cropGroups;
    const groups = ExtractCrops(crop_groups);
    this.setState({ crop_groups: groups });
  };

  componentWillUnmount() {
    this.handleClose();
  }
  render() {
    const { errors, crop_groups, partner_groups, data, loading } = this.state;
    const { accepted_Files, rejected_Files, partner, crop, year } = data;

    //if (this.props.isVisible && !plusPressed) this.updateState();

    return (
      <div
        className={
          !this.props.isVisible
            ? "upload-files-container col-lg-4 col-sm-5 col-xs-10"
            : "upload-files-container col-lg-4 col-sm-5 col-xs-10 opened"
        }
      >
        <button
          type="button"
          onClick={this.handleClose}
          className="close"
          aria-label="Close"
          disabled={loading}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <h2>Upload Shape Files:</h2>
        <Input
          name={"tag"}
          value={data["tag"]}
          label={"Tag"}
          type="text"
          placeholder={"tag"}
          onChange={this.handleChange}
        />
        {this.props.isAdmin ? (
          <SelectGroup
            groups={partner_groups}
            handleChange={value => this.handleChange(value, "partner")}
            value={partner}
            error={errors["partner"]}
            type="partnerCombo"
          />
        ) : (
          ""
        )}

        <SelectGroup
          groups={crop_groups}
          handleChange={value => this.handleChange(value, "crop")}
          value={crop}
          name="crop"
          error={errors["crop"]}
          type="crop"
        />

        <SelectGroup
          groups={GenerateYears()}
          handleChange={this.handleChange}
          value={year}
          error={errors["year"]}
          type="year"
          name="year"
        />

        <FileUpload
          handleDrop={this.handleDrop}
          disable={true}
          loading={this.state.loading}
        >
          {accepted_Files.length === 0 && rejected_Files.length === 0 && (
            <DefaultDropZone />
          )}
          {/* If there is accepted files then render them */}
          {accepted_Files.length > 0 && (
            <RenderFiles
              files={accepted_Files}
              handleDelete={this.handleDelete}
              accpeted={true}
            />
          )}
          {/* If there is rejected files then render them */}
          {rejected_Files.length > 0 && (
            <RenderFiles
              files={rejected_Files}
              handleDelete={this.handleDelete}
              accpeted={false}
              message="Conversion Failed"
            />
          )}
        </FileUpload>

        <FileUpload handleDrop={this.handleDrop} styleIgnore={true}>
          <button
            style={{ float: "right" }}
            className="btn btn-default file-upload"
            disabled={loading}
          >
            Browse
          </button>
        </FileUpload>

        <button
          className="btn btn-default file-upload"
          onClick={this.handleSubmit}
          disabled={loading}
        >
          submit
        </button>
      </div>
    );
  }
}

export default Upload;
