import React, { Component } from "react";

class WhyParticipate extends Component {
  state = {};
  render() {
    return (
      <div className="container">
        <div className="content">
          <h2 className="page-header">Why Participate?</h2>
          <ul>
            <li>
              IP360 is a simple, transparent system for merchandisers to use in
              a secure environment.
            </li>
            <li>
              IP360 data will be constantly updated with information on various
              IP corn crops offering collaborators a chance to view planting
              intentions on a ‘near real time’ basis.
            </li>
            <li>
              IP360 will provide the convenience of being on-line for easy
              viewing while providing company contact information for additional
              questions (no grower contact information will be shared on IP360).
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default WhyParticipate;
