import React, { Component } from "react";
import MapFilter from "./mapfilter";
import Upload from "./upload";
import Map from "./map";
import { HttpGet_cropGroup, HttpGet_years, HttpGet_allYears } from "./../services/APIsevices";
import {storage} from "../utils/storage";
import _ from "lodash";

class Home extends Component {
  state = {
    map: null,
    selectedYear: null,
    selectedCropGroups: [],
    yearCropGroups: [],
    years: [],
    uploads: [],
    cropGroups: []
  };

  async componentDidMount() {
    var years = await this.getCurrentYearList();
    this.setState({ years, selectedYear: years.length ? years[0] : null });
  }

  initializeMap = map => {
    this.setState({ map: map });
  };

  setSelectedYear = year => {
    this.setState({
      selectedYear: year
    });
  };

  setSelectedCropGroups = cropGroups => {
    var groups = [...this.state.selectedCropGroups];
    groups.splice(0, this.state.selectedCropGroups.length, ...cropGroups);
    this.setState({
      selectedCropGroups: groups
    });
  };

  setYearCropGroups = yearCropGroups => {
    this.setState({
      yearCropGroups
    });
  };

  getCropGroups = async () => {
    const groups = await HttpGet_cropGroup();
    this.setState({ cropGroups: groups });
  };

  manageSavedYearsData=(keys)=>{
    _.forEach(keys, key=>{
      storage.remove('map', `auth_${key}`);
      storage.remove('map', `auth_${key}_MapData`);
      storage.remove('map', `nonauth_${key}`);
      storage.remove('map', `nonauth_${key}_MapData`);
    });
  }

  async getCurrentYearList() {
    let years = await HttpGet_years();
    let allYears = await HttpGet_allYears();
    let keys = _.difference(allYears, years);
    this.manageSavedYearsData(keys);
    // var currentYear = new Date().getFullYear();
    if (!years) {
      years = [];
    }

    // if (years.indexOf(currentYear) < 0) {
    //   years.push(currentYear);
    //   years = years.sort().reverse();
    // }
    return years;
  }

  async refreshCurrentYearList() {
    let updatedYearList = await this.getCurrentYearList();
    var years = [...this.state.years];
    years.splice(0, this.state.years.length, ...updatedYearList);
    this.setState({ years });
  }

  fileUploaded = async uploadYear => {
    var uploads = [].concat(this.state.uploads, uploadYear);
    this.setState({ uploads });

    if (this.state.years.indexOf(uploadYear) < 0) {
      await this.refreshCurrentYearList();
    }
  };

  fileDeleted = async () => {
    await this.refreshCurrentYearList();
  };

  componentWillUnmount() {
    this.props.closeUploadComponent();
    // console.log("unmount");
    // this.state = Object.assign({}, this.state, Init_state);
    // console.log("old state", this.state);
  }

  render() {
    return (
      <React.Fragment>
        <MapFilter
          map={this.state.map}
          setSelectedYear={this.setSelectedYear}
          setSelectedCropGroups={this.setSelectedCropGroups}
          selectedYear={this.state.selectedYear}
          selectedCropGroups={this.state.selectedCropGroups}
          cropGroups={this.state.yearCropGroups}
          years={this.state.years}
          toggleHideUploadOptionMobileView={
            this.props.toggleHideUploadOptionMobileView
          }
        />
        <Map
          userCanHaveLayers={this.props.userCanHaveLayers}
          userMaximumAllowedZoom={this.props.userMaximumAllowedZoom}
          initializeMap={this.initializeMap}
          selectedYear={this.state.selectedYear}
          selectedCropGroups={this.state.selectedCropGroups}
          setYearCropGroups={this.setYearCropGroups}
          setSelectedCropGroups={this.setSelectedCropGroups}
          uploads={this.state.uploads}
          cropGroups={this.state.cropGroups}
          getCropGroups={this.getCropGroups}
          isAdmin={this.props.isAdmin}
          authenticated={this.props.authenticated}
          />
        <Upload
          isVisible={this.props.uploadComponentVisible}
          onCloseClicked={this.props.closeUploadComponent}
          isAdmin={this.props.isAdmin}
          user={this.props.user}
          isLoading={this.props.isLoading}
          fileUploaded={this.fileUploaded}
          partners={this.props.partners}
          getPartners={this.props.getPartners}
          cropGroups={this.state.cropGroups}
          getCropGroups={this.getCropGroups}
        />
      </React.Fragment>
    );
  }
}

export default Home;
