import React, { Component } from "react";
import { HttpGet_users } from "../services/APIsevices";
import { Redirect } from "react-router-dom";
import UsersTable from "./usersComponents/usersTable";
import SendInviation from "./usersComponents/sendInvitation";
import { SortFunction } from "../services/userService";
import { toast } from "react-toastify";

class ManageUsers extends Component {
    state = {
        redirect: false,
        modalOpen: false,
        loading: false,
        users: [],
        filteredUsers: [],
        searchWord: ""
    };

    async componentDidMount() {
        await this.props.setUserAuthenticationState();
        if (!this.props.isAdmin) {
            this.setState({ redirect: true });
        }
        this.getUsers();
    }

    getUsers = () => {
        this.setState({ loading: true });
        HttpGet_users().then(users => {
            if (users && users.length)
                users = users.map(user => {
                    user.invitation_date = user.invitation_date ? new Date(user.invitation_date) : "";
                    user.last_login_date = user.last_login_date ? new Date(user.last_login_date) : "";
                    return {
                        ...user,
                        name: user.first_name + ' ' + user.last_name,
                        is_deleted: user.is_deleted ? "Yes" : 'No',
                        invitationDate: user.invitation_date ? `${user.invitation_date.getFullYear()}-${user.invitation_date.getMonth() + 1}-${user.invitation_date.getDate()} ${user.invitation_date.getHours()}:${user.invitation_date.getMinutes()}:${user.invitation_date.getSeconds()}` : "",
                        lastLoginDate: user.last_login_date ? `${user.last_login_date.getFullYear()}-${user.last_login_date.getMonth() + 1}-${user.last_login_date.getDate()} ${user.last_login_date.getHours()}:${user.last_login_date.getMinutes()}:${user.last_login_date.getSeconds()}` : "",
                    };
                });
            this.setState({ users, filteredUsers: users, loading: false });
        }).catch(() => {
            this.setState({ loading: false });
            toast.error("An error ocurred");
        });
    }

    refreshUsersData = () => {
        this.getUsers();
        this.setState({ searchWord: "" });
    }

    redirectToNotAuthirized = () => {
        this.props.history.push(`/not-authorized`);
    }

    handleResetSearch = () => {
        this.setState({ filteredUsers: [...this.state.users], searchWord: "" });
    }

    handleSearchChange = (evt) => {
        let searchValue = evt.target.value.toLowerCase();
        let users = [...this.state.users];
        users = users.filter(user => {
            return (user.name && user.name.toLowerCase().indexOf(searchValue) > -1) ||
                (user.email && user.email.indexOf(searchValue) > -1) ||
                (user.role && user.role.toLowerCase().indexOf(searchValue) > -1) ||
                (user.is_deleted && user.is_deleted.toLowerCase().indexOf(searchValue) > -1) ||
                (user.invitationDate && user.invitationDate.toLowerCase().indexOf(searchValue) > -1) ||
                (user.lastLoginDate && user.lastLoginDate.toLowerCase().indexOf(searchValue) > -1);
        });
        this.setState({ searchWord: evt.target.value, filteredUsers: [...users] });

    }

    openModal = () => {
        this.setState({ modalOpen: true });
    };

    closeModal = () => {
        this.setState({ modalOpen: false });
    };

    handleSort = (rows, field, direction) => {
        rows.sort(SortFunction(field, direction));
        return rows.slice(0);
    };

    render() {
        return this.state.redirect ? (
            <Redirect to={{ pathname: "/not-authorized" }} />
        ) : (
                <div className="main">
                    <div className="container">
                        <div className="content">
                            <h1 className="page-header">Manage Users</h1>
                            <div className="row">
                                <div className="col-sm-2 pull-right" style={{ marginBottom: '20px' }}>
                                    <button className="btn WidthFull" onClick={this.openModal}>Invite User</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <input type="text" placeholder="Search" value={this.state.searchWord} onChange={this.handleSearchChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <button className="btn" style={{ marginTop: 2, backgroundColor: '#888f94' }} onClick={this.handleResetSearch}>Reset</button>
                                    </div>
                                </div>
                            </div>
                            <UsersTable users={this.state.filteredUsers} loading={this.state.loading} refreshUsersData={this.refreshUsersData} handleSort={this.handleSort} />
                            <div className="clearfix" />
                        </div>
                    </div>
                    <SendInviation modalOpen={this.state.modalOpen} closeModal={this.closeModal} refreshUsersData={this.refreshUsersData} />
                </div>
            );
    }
}

export default ManageUsers;
