import React, { Component } from "react";

class About extends Component {
  state = {};
  render() {
    return (
      <div className="container">
        <div className="content">
          <h2 className="page-header">What is IP360?</h2>
          <p className="text mb30">
            IP360 is an online repository of identity preserved field locations,
            starting with corn, but potentially expanding to other crops over
            time.
          </p>
          <h2 className="page-header">What is the Vision?</h2>
          <p className="text mb30">
            The vision of IP360 is to provide clarity to identity preserved
            stakeholders on the location of contracted fields for use in
            planting intentions, understanding in-season field locations as well
            as support merchandisers buying grain for current or previous crops.
            IP360 will distinguish different crops via a color-coding system
            which is accomplished by providing a geographical view of field
            locations at various ‘zoom levels’ depending on information that
            participants/users/collaborators are willing and able to provide.
          </p>
          <p className="text">
            That IP360 will be adopted by collaborators, who participate by
            uploading their field locations into the system.
          </p>
          <p className="text">Examples of collaborators are those:</p>
          <ul className="mb30">
            <li>That grow production (seed) fields.</li>
            <li>That contract for and purchase Non-GMO and Organic corn.</li>
            <li>
              That contract for and purchase food grade corn, either yellow,
              white, or specialty.
            </li>
            <li>That contract for sweet corn and purchase sweet corn.</li>
            <li>That process corn with specific traits.</li>
          </ul>
          <p className="text mb30">
            For users that are currently using the Land.db platform through Ag
            Connections, data will be uploaded real-time (Enogen as an example),
            and for users on other platforms data will be uploaded upon receipt
            of their files.
          </p>
          <h2 className="page-header">What is the Value?</h2>
          <p className="text">
            IP360, when used in collaboration, can help assure spacial isolation
            of corn fields where grain purity is of concern.
          </p>
          <ul className="mb30">
            <li>
              This is accomplished by knowing in advance of planting, the intent
              of field usage.
            </li>
          </ul>
          <p className="text">
            Historical data and years of experience has shown that coexistence
            of planted corn technologies is possible through advanced
            communication and planning. This can:
          </p>
          <ul className="mb30">
            <li>
              Provide end-users clarity on field locations of various crops.
            </li>
            <li>
              Provide transparency for identity preserved corn contractors.
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default About;
