import React, { Component } from "react";
import CropsTables from "./CropComponents/cropsTable";
import { HttpGet_cropGroup, HttpPut_cropGroup } from "../services/APIsevices";
import { toastSuccess, toastError } from "../services/uploadService";
import { Redirect } from "react-router-dom";

class ManageCrops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cropGroups: [],
      modifiedCropGroups: [],
      saved: false,
      reset: false,
      redirect: false
    };
    this.callAPI();
    this.props.ResetFn(false);
  }
  async componentDidMount() {
    await this.props.setUserAuthenticationState();
    if (!this.props.isAdmin) {
      this.setState({ redirect: true });
    }
  }

  callAPI() {
    HttpGet_cropGroup().then(cropGroups => {
      cropGroups.forEach(group => {
        group.displayColorPicker = false;
        group.displayLineStyles = false;
      });
      this.setState({ cropGroups });
    });
  }

  //save changes from the child component in parent component
  handelChange = NewcropGroups => {
    this.setState({
      modifiedCropGroups: NewcropGroups,
      reset: true,
      saved: false
    });
    this.props.ResetFn(true);
  };

  //call API to save the crops
  handelSubmit = async () => {
    this.props.ResetFn(false);

    let { modifiedCropGroups } = this.state;
    modifiedCropGroups.forEach(group => {
      delete group.displayColorPicker;
      delete group.displayLineStyles;
    });

    // console.log(modifiedCropGroups);
    if (modifiedCropGroups.length > 0) {
      const res = await HttpPut_cropGroup(modifiedCropGroups);
      // console.log(res);
      modifiedCropGroups = [];
      this.setState({ modifiedCropGroups, saved: true, reset: false });
      if (res) toastSuccess("Crop/s Updated Successfully.");
      else toastError("Unexpected Error! Unable to update crop/s.");
    } else toastError("No crop/s are modified.");
  };

  handleReset = () => {
    this.setState({ modifiedCropGroups: [], reset: false });
    this.props.ResetFn(false);
  };

  redirectToNotAuthirized = () => {
    this.props.history.push(`/not-authorized`);
  };

  render() {
    let { modifiedCropGroups, reset, saved } = this.state;
    const cropGroups = this.state.cropGroups;

    return this.state.redirect ? (
      <Redirect to={{ pathname: "/not-authorized" }} />
    ) : (
      <div className="main">
        <div className="container">
          <div className="content">
            <h1 className="page-header">Manage Crops Layout</h1>

            <CropsTables
              cropGroups={cropGroups}
              modifiedCrops={modifiedCropGroups}
              onChange={this.handelChange}
              reset={reset}
              saved={saved}
            />

            <button
              onClick={this.handelSubmit}
              className="btn btn-xl pull-right delete-btn"
            >
              Save
            </button>
            {/* <button onClick={this.handleReset} className="btn btn-xl pull-left">
              Reset
            </button> */}
            <div className="clearfix" />
          </div>
        </div>
      </div>
    );
  }
}

export default ManageCrops;

// {
//   name: "tomato",
//   id: "1234",
//   color: "#452ACE",
//   displayColorPicker: false
// },
// {
//   name: "corn",
//   id: "3456",
//   color: "#419C53",
//   displayColorPicker: false
// }
