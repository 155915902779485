import axios from 'axios';
import { storage } from '../utils/storage';
import { GetConfirguration } from '../services/APIsevices'

async function getAllCachedData(year, authenticated) {
    let auth = authenticated ? 'auth' : 'nonauth';
    let p1 = storage.get('map', `${auth}_${year}`);
    let p2 = storage.get('map', `${auth}_${year}_MapData`);
    var cachedData = await Promise.all([p1, p2]);
    return {
        version: cachedData[0],
        points: cachedData[1]
    };
}

async function getData(year, authenticated) {
    let auth = authenticated ? 'auth' : 'nonauth';
    const cachedData = await getAllCachedData(year, authenticated);
    let points = null;

    let config = {
        ...GetConfirguration(),
        validateStatus: function (status) {
            return status < 400;
        },
        headers: {
            etag: cachedData.version,
            ...GetConfirguration().headers
        }
    };

    let response = await axios.get(`crop-specialities?year=${year}`, config);

    if (response.status === 304) {
        points = cachedData.points;
    }
    else if (response.status === 200) {
        points = response.data;
        if (response.headers.etag && response.headers.etag != "null") {
            const version = response.headers.etag.split('_')[1];
            storage.set('map', `${auth}_${year}_MapData`, points);
            storage.set('map', `${auth}_${year}`, version);
        }
    }

    return { points };
}


export var mapservice = {
    getData
};