import React, { Component } from "react";
import { HttpGet_cropYearsSettings, HttpPut_cropYearsSettings } from "../services/APIsevices";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class ManageCropYears extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cropYears: [],
            redirect: false
        };
    }
    async componentDidMount() {
        await this.props.setUserAuthenticationState();
        if (!this.props.isAdmin) {
            this.setState({ redirect: true });
        }
        this.getCropYearsSettings();
    }

    getCropYearsSettings() {
        HttpGet_cropYearsSettings().then(cropYears => {
            this.setState({ cropYears });
        });
    }

    handelChange = cropYear => {
        cropYear.visible = !cropYear.visible;
        HttpPut_cropYearsSettings(cropYear).then(() => {
            let cropYears = [...this.state.cropYears];
            let selectedCropYear = cropYears.filter(y => y.year === cropYear.year);
            selectedCropYear.visible = cropYear.visible;
            this.setState({ cropYears: cropYears })
            toast.success("Changes has been saved successfully");
        }).catch(() => {
            toast.error("An Error ocurred");
        })
    };

    redirectToNotAuthirized = () => {
        this.props.history.push(`/not-authorized`);
    };

    render() {
        const cropYears = this.state.cropYears;
        return this.state.redirect ? (
            <Redirect to={{ pathname: "/not-authorized" }} />
        ) : (
                <div className="main">
                    <div className="container">
                        <div className="content">
                            <h1 className="page-header">Manage Crop Years</h1>
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Visible</th>
                                            <th>Year</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cropYears.map(cropYear => {
                                            return <tr key={cropYear.year}>
                                                <td>
                                                    <input type="checkbox"
                                                        checked={cropYear.visible}
                                                        onChange={() => this.handelChange(cropYear)} />
                                                </td>
                                                <td>{cropYear.year}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="clearfix" />
                        </div>
                    </div>
                </div>
            );
    }
}

export default ManageCropYears;
