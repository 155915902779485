import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "./AdditionalNavbar.css";
const user35 = require("../assets/img/user-35.png");
const user50 = require("../assets/img/user-50.png");

class Navbar extends Component {
  state = {
    collapse: false,
    collapseNavLinks: true,
    maxMobileWidth: 768,
    userOptions: false
  };

  componentDidMount() {
    this.screenWidthChanged();
    window.addEventListener("resize", this.screenWidthChanged);
  }

  screenWidthChanged = () => {
    const dynamicHeight = document.getElementsByClassName("header-standard")[0]
      .clientHeight;
    let vh = (window.innerHeight - dynamicHeight) * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    this.setState({
      collapseNavLinks: window.innerWidth >= this.state.maxMobileWidth
    });
  };

  handleClick = LoginClicked => {
    this.setState({ collapse: !this.state.collapse });
    if (LoginClicked === true) this.props.onLoginClicked();
  };

  openUserOptions = () => {
    // console.log("called");
    this.setState({ userOptions: !this.state.userOptions });
  };

  render() {
    return (
      <div className="header header-standard">
        <div className="container">
          <div className="header-inner">
            <div className="header-main">
              <div className="header-title">
                <Link to="/">IP360</Link>
              </div>
              <div className="header-navigation">
                <div
                  className={
                    this.state.collapse
                      ? "nav-main-wrapper open"
                      : "nav-main-wrapper"
                  }
                >
                  <div className="nav-overlay" onClick={this.handleClick} />
                  <div className="nav-main-title visible-xs">
                    <Link to="/" onClick={this.handleClick}>
                      IP360
                    </Link>
                  </div>

                  <div className="nav-main-inner">
                    <nav>
                      <ul id="nav-main" className="nav nav-pills">
                        <li className="user-area visible-xs">
                          {!this.props.authenticated ? (
                            <a
                              className="btn btn-default"
                              onClick={() => this.handleClick(true)}
                            >
                              Login
                            </a>
                          ) : (
                              <React.Fragment>
                                <label>
                                  <img src={user50} /> {`Hi ${this.props.user}`}
                                </label>
                              </React.Fragment>
                            )}
                          <div className="clearfix" />
                        </li>
                        <li>
                          <NavLink exact to="/" onClick={this.handleClick}>
                            Home <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>

                        <li>
                          <NavLink to="/about" onClick={this.handleClick}>
                            What is IP360 ?
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            to="/whyparticipate"
                            onClick={this.handleClick}
                          >
                            Why Participate ?
                          </NavLink>
                        </li>
                        {!this.props.isAdmin ? null : (
                          <li className="has-children ">
                            <NavLink to="#">
                              Administration
                              <i className="fa fa-caret-down" />
                            </NavLink>

                            <div>
                              <NavLink to="#">
                                Administration{" "}
                                <i className="fa fa-caret-down" />
                              </NavLink>

                              <ul className="sub-menu">
                                <li>
                                  <NavLink
                                    to="/manage-crops"
                                    onClick={this.handleClick}
                                  >
                                    Manage Crops Layout
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="/manage-years"
                                    onClick={this.handleClick}
                                  >
                                    Manage Crop Years
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="/manage-users"
                                    onClick={this.handleClick}
                                  >
                                    Manage Users
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="/manage-files"
                                    onClick={this.handleClick}
                                  >
                                    Manage Uploaded Files
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </li>
                        )}
                        <li>
                          <div className="contact-area visible-xs">
                            <i className="fa fa-phone" />
                            <div className="header-info-item">
                              <a href="tel:270-435-4369">
                                <strong>270-435-4369</strong>
                              </a>
                              <a href="mailto:support@agconnections.com">
                                <span>support@agconnections.com</span>
                              </a>
                            </div>
                          </div>
                          <div className="clearfix" />
                        </li>
                        {this.props.authenticated ? (
                          <li className="user-area visible-xs">
                            <a
                              className="btn btn-default"
                              onClick={this.props.onLogoutClicked}
                            >
                              <i className="fa fa-sign-out " /> Logout
                            </a>
                          </li>
                        ) : null}
                      </ul>
                    </nav>
                  </div>
                </div>
                <button
                  type="button"
                  className="navbar-toggle"
                  data-target="#myNavbar"
                  aria-controls="myNavbar"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={this.handleClick}
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
              </div>

              <div id="myNavbar" className="pull-right hidden-xs">
                <div className="contact-area">
                  <i className="fa fa-phone" />
                  <div className="header-info-item pull-left">
                    <a href="tel:270-435-4369">
                      <strong>270-435-4369</strong>
                    </a>
                    <br />
                    <a href="mailto:support@agconnections.com">
                      <span>support@agconnections.com</span>
                    </a>
                  </div>
                </div>

                <div className="user-area hidden-xs">
                  {!this.props.authenticated ? (
                    <a
                      className="login-link"
                      onClick={() => this.handleClick(true)}
                    >
                      Login
                    </a>
                  ) : (
                      <React.Fragment>
                        <label>
                          <div onClick={this.openUserOptions}>
                            <img src={user35} />{" "}
                          </div>{" "}
                          <div
                            onBlur={this.openUserOptions}
                            onFocus={this.openUserOptions}
                          >
                            <ul
                              className={
                                !this.state.userOptions
                                  ? `user-options hide`
                                  : `user-options`
                              }
                            >
                              <li onClick={this.openUserOptions}>
                                <img src={user50} />{" "}
                                <div
                                  className="pull-left"
                                  onClick={this.openUserOptions}
                                >
                                  <span>Hi,</span>
                                  <br /> <b>{`${this.props.user}`}</b>
                                  <br /><span>{this.props.isAdmin? '(Admin)': '(Partner)'}</span>
                                </div>
                                <div className="clearfix" />
                              </li>
                              <li onClick={this.props.onLogoutClicked}>
                                <i className="fa fa-sign-out " /> Logout
                            </li>
                            </ul>
                          </div>
                        </label>
                      </React.Fragment>
                    )}
                </div>
                <div
                  style={
                    !this.state.collapseNavLinks ? null : { display: "none" }
                  }
                >
                  <a href="tel:270-435-4369">
                    <strong>270-435-4369</strong>
                  </a>
                  <a href="mailto:support@agconnections.com">
                    <span>support@agconnections.com</span>
                  </a>
                </div>
              </div>

              {this.props.isAdmin &&
                !this.props.hideUploadOptionMobileView &&
                window.location.pathname === "/" && (
                  <a
                    className="header-action"
                    title="Upload Shape Files"
                    onClick={this.props.onUploadClicked}
                  >
                    <i className="fa fa-plus" />
                  </a>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
