import React, { Component } from "react";
import SelectGroup from "./uploadComponents/selectGroup";
import Input from "./uploadComponents/input";
import Popup from "react-popup";
import "react-popup/style.css";
import {
  HttpGet_cropGroup,
  HttpGet_uploadJob,
  HttpDelete_uploadJob
} from "./../services/APIsevices";
import {
  ExtractCrops,
  ExtractPartners,
  GenerateYears
} from "../services/uploadService";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CropComponents/datePicker.css";
import FilesTable from "./CropComponents/filesTable";

import { toastError, toastSuccess } from "../services/uploadService";
import { Redirect } from "react-router-dom";

class ManageFiles extends Component {
  initialState = {
    data: {
      partner: "",
      crop: "",
      year: "",
      tag: "",
      startDate: "",
      endDate: ""
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      data: {
        partner: "",
        crop: "",
        year: "",
        tag: "",
        startDate: "",
        endDate: ""
      },
      crop_groups: [],
      partner_groups: [],
      search_result: [],
      delete_array: [],
      DateError: false,
      redirect: false,
      loading: false
    };
    this.callAPI();
  }

  async componentDidMount() {
    await this.props.setUserAuthenticationState();
    if (!this.props.isAdmin) {
      this.setState({ redirect: true });
    }
  }

  async callAPI() {
    const crop_groups = await HttpGet_cropGroup();
    if (
      this.props.partners.data === undefined ||
      this.props.partners.data.length === 0
    ) {
      await this.props.getPartners();
    }
    const extractedPartners = ExtractPartners(this.props.partners.data);
    const groups = ExtractCrops(crop_groups);

    this.setState({ crop_groups: groups, partner_groups: extractedPartners });
  }

  handleChange = (input, name) => {
    if (input === null) {
      return;
    }
    input = input.target || input;

    const data = { ...this.state.data };

    const date = new Date(input);
    const isValid = date instanceof Date && !isNaN(date);

    if (input.name === "endDate" || name === "endDate") {
      if (data.startDate && data.startDate > date) {
        this.setState({ DateError: true });
        return;
      } else this.setState({ DateError: false });
    } else if (input.name === "startDate" || name === "startDate") {
      if (data.endDate && data.endDate < date)
        this.setState({ DateError: true });
      else this.setState({ DateError: false });
    }
    data[input.name || name] = input.value || (isValid ? date : null);

    this.setState({ data });
  };

  isEmpty = str => {
    let empty = !str || 0 === str.length;
    if (empty) return !str || /^\s*$/.test(str);
    return empty;
  };

  handleReset = () => {
    var newSearchResult = [...this.state.search_result];
    newSearchResult.splice(0, this.state.search_result.length);
    this.setState({
      data: this.initialState.data,
      search_result: newSearchResult,
      DateError: false
    });
  };

  handleSearch = async () => {
    const { data, crop_groups, DateError } = this.state;
    if (DateError) {
      return;
    }
    let filledObject = {};
    //check first if there is any criteria entered by the user
    let isNull = Object.values(data).every(
      prop => prop === null || prop === ""
    );
    if (isNull)
      toastError("You must enter at least one criteria to search with.");
    //Send request to Get the result depending on the crop, year, partner, tag, and dates criteria
    else {
      this.setState({ loading: true });
      Object.keys(data).forEach(prop => {
        if (!this.isEmpty(data[prop])) {
          let renamProp;
          let value = data[prop];

          if (prop === "crop") renamProp = "crop_id";
          else if (prop === "year") renamProp = "crop_year";
          else if (prop === "endDate") {
            value = new Date(value);
            value.setDate(value.getDate() + 1);
          }
          renamProp = renamProp !== undefined ? renamProp : prop;
          filledObject[renamProp] = value;
        }
      });

      // console.log(filledObject);
      let res = await HttpGet_uploadJob(filledObject);
      //Reconstruct the Result to contain the right format
      let searchResult =
        res &&
        res.data &&
        res.data.map(row => {
          row.updated_at = new Date(row.updated_at)
            .toLocaleString()
            .split(",")[0];
          row.crop_name = crop_groups.filter(group =>
            group.options.filter(item => item.id === row.crop_id)
          );
          crop_groups.filter(group => {
            return group.options.forEach(option => {
              if (option.id === row.crop_id) {
                row.crop_name = group.name;
                return;
              }
            });
          });
          return row;
        });

      // console.log(searchResult);
      this.setState({ search_result: searchResult, loading: false });
    }
  };

  //To get the indices of objects inside array from another array
  getIndices = (Arr, SubArr) => {
    let indices = [];
    SubArr.forEach(object => {
      const index = Arr.findIndex(
        obj =>
          obj.crop_id === object.crop_id &&
          obj.crop_year === object.crop_year &&
          obj.partner === object.partner
      );
      if (index > -1) indices.push(index);
    });
    return indices.sort((a, b) => (a > b ? -1 : a < b ? 1 : 0));
  };

  checkDelete = () => {
    const { delete_array } = this.state;
    if (delete_array.length > 0)
      Popup.create({
        title: null,
        content: "Are you sure you want to delete the selected crop/s?",
        buttons: {
          right: [
            {
              text: "Cancel",
              className: "danger",
              action: () => {
                Popup.close();
              }
            }
          ],
          left: [
            {
              text: "Ok",
              className: "success",
              action: () => {
                this.handleDelete();
                Popup.close();
              }
            }
          ]
        }
      });
    else toastError("You must select at least one row to delete.");
  };

  handleDelete = async () => {
    // console.log("DELETE");
    const { search_result, delete_array } = this.state;
    // console.log(delete_array);
    const result = await HttpDelete_uploadJob(delete_array);
    if (result) {
      toastSuccess("Crop/s are deleted successfully");
      const indices = this.getIndices(search_result, delete_array);
      if (indices.length > 0)
        indices.forEach(index => {
          search_result.splice(index, 1);
        });
      this.setState({ search_result, delete_array: [] });

      await this.props.getPartners();
      const extractedPartners = ExtractPartners(this.props.partners.data);
      this.setState({ partner_groups: extractedPartners });
    } else toastError("Unexpected Error! No crop/s were deleted!");
  };

  render() {
    const {
      crop_groups,
      partner_groups,
      data,
      search_result,
      delete_array,
      DateError,
      loading
    } = this.state;
    const { crop, year, partner } = data;

    return this.state.redirect ? (
      <Redirect to={{ pathname: "/not-authorized" }} />
    ) : (
      <div className="main">
        <div className="container">
          <div className="content">
            <h1 className="page-header">Manage Uploaded Files</h1>

            <div className="row">
              <div className="col-sm-3">
                <SelectGroup
                  groups={crop_groups}
                  handleChange={this.handleChange}
                  value={crop}
                  type="crop"
                />
              </div>
              <div className="col-sm-3">
                <SelectGroup
                  groups={GenerateYears()}
                  handleChange={this.handleChange}
                  value={year}
                  type="year"
                />
              </div>
              <div className="col-sm-3">
                <Input
                  name={"tag"}
                  value={data["tag"]}
                  label={"Tag"}
                  type="text"
                  placeholder={"tag"}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-sm-3">
                <SelectGroup
                  groups={partner_groups}
                  handleChange={this.handleChange}
                  handleInputChange={this.handleInputChange}
                  value={partner}
                  type="partner"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <h4>Date</h4>
              </div>
              <div className="col-sm-3 form-group">
                <DatePicker
                  className="picker"
                  placeholderText="From"
                  selected={data.startDate || null}
                  onChange={date => this.handleChange(date, "startDate")}
                />
              </div>
              <div className="col-sm-3 form-group">
                <DatePicker
                  placeholderText="To"
                  className="picker"
                  selected={data.endDate || null}
                  onChange={date => this.handleChange(date, "endDate")}
                  //   maxDate={new Date()}
                />
                {DateError && (
                  <div className="alert alert-danger">Invalid date</div>
                )}
              </div>

              <div className="col-sm-2 pull-right">
                <button className="btn WidthFull" onClick={this.handleReset}>
                  Reset
                </button>
              </div>
              <div className="col-sm-2 pull-right">
                <button className="btn WidthFull" onClick={this.handleSearch}>
                  Search
                </button>
              </div>
            </div>
            <hr />
            <Popup className="mm-popup" btnClass="mm-popup__btn" />
            <FilesTable
              result={search_result}
              deleteArr={delete_array}
              reset={true}
              loading={loading}
            />
            <div className="pull-right">
              <button
                className="btn delete-btn"
                onClick={this.checkDelete}
                disabled={
                  search_result && search_result.length > 0 ? false : true
                }
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageFiles;

// let renamProp;
//           let isNumber = false,
//             exactMatch = false;
//           if (prop === "crop") {
//             renamProp = "crop_id";
//             exactMatch = true;
//           } else if (prop === "year") {
//             renamProp = "crop_year";
//             isNumber = true;
//             exactMatch = true;
//           }
//           renamProp = renamProp !== undefined ? renamProp : prop;
//           filledObject[renamProp] = data[prop];
//           if (!exactMatch)
//             WhereQuery += `body->>'${renamProp}' = ${
//               isNumber ? data[prop] : `'${data[prop]}'`
//             } AND `;
//           else
//             WhereQuery += `body->>'${renamProp}' LIKE '${data[
//               prop
//             ].toLowerCase()}%' AND `;
