import L from 'leaflet';

var mapLegend = L.Control.extend({
    options: {
        position: 'bottomright'
    },
    initialize: function (options) {
        L.Util.setOptions(this, options);
    },
    onAdd: function () {
        var mainLegend = document.querySelector('.main-legend');
        var button = L.DomUtil.create('div');
        L.DomUtil.addClass(button, 'leaflet-bar leaflet-legend');
        var anchor = L.DomUtil.create('a');
        anchor.setAttribute('href', '#');
        anchor.setAttribute('role', 'button');
        button.appendChild(anchor);
        L.DomEvent.on(button, 'dblclick', function (e) {
            L.DomEvent.stopPropagation(e);
        });
        L.DomEvent.on(button, 'click', function (e) {
            L.DomEvent.stopPropagation(e);
            L.DomEvent.preventDefault(e);
            if (L.DomUtil.hasClass(mainLegend, 'hide')) {
                L.DomUtil.removeClass(mainLegend, 'hide');
            } else L.DomUtil.addClass(mainLegend, 'hide');
        });

        return button;
    },
    onRemove: function () {
    }
});

export var mapLegendControl = new mapLegend();