
import React, { Component } from "react";
import { HttpPost_deleteUser } from "../../services/APIsevices";
import { toast } from "react-toastify";
import Popup from "react-popup";
import "react-popup/style.css";

class RemoveUserButton extends Component {
    
    removeButtonClicked = ()=>{
        Popup.create({
            title: null,
            content: "Are you sure you want to delete this user?",
            buttons: {
              right: [
                {
                  text: "Cancel",
                  className: "danger",
                  action: () => {
                    Popup.close();
                  }
                }
              ],
              left: [
                {
                  text: "Ok",
                  className: "success",
                  action: () => {
                    this.handleRemoveUser();
                  }
                }
              ]
            }
          });
    }
    handleRemoveUser = () => {
        let email = this.props.user.email;
        HttpPost_deleteUser({
            email: email,
            role: this.props.user.role
        }).then(() => {
            toast.success("User has been deleted successfully");
            this.props.refreshUsersData();
            Popup.close();
        }).catch(err=>{
            toast.error(err);
            Popup.close();
        });
    };

    render() { 
        return ( 
            <React.Fragment>
                <Popup className="mm-popup" btnClass="mm-popup__btn" />
                <button onClick={ this.removeButtonClicked} className="btn delete-btn user-delete-button">
                    Remove
                </button>
            </React.Fragment>);
    }
}
 
export default RemoveUserButton;