import React from "react";

const DefaultDropZone = () => {
  return (
    <p>
      Drag and Drop Files or click to browse
      <br />
      <span className="help">
        Allowed extensions are *.shp, *.dbf, .shx or *.zip
      </span>
    </p>
  );
};

export default DefaultDropZone;
