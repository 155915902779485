import React, { Component } from "react";

class NotAuthorized extends Component {
  state = {};
  render() {
    return (
      <h2 style={{ textAlign: "center" }}>
        Sorry, User is not authorized to open this page
      </h2>
    );
  }
}

export default NotAuthorized;
