import React, { Component } from "react";
import Modal from "react-modal";
import { storageService } from '../../services/storageService';


const customStyles = {
    content: {
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0,.12)',
        zIndex: 99999,
    }
};


export default class LegalDisclamar extends Component {

    state = {
        modalOpen: true
    };

    handleAgreement = () => {
        storageService.addItem("Aggrement_Disclamar", true);
        this.setState({ modalOpen: false });
        this.props.agreementDisc(false);

    }

    openModal() {
        this.setState({ modalOpen: true });
    }


    componentDidMount() {
        const openModal = storageService.getItem('Aggrement_Disclamar') ? false : true;
        this.setState({ modalOpen: openModal })
        this.props.agreementDisc(openModal);
    }

    render() {
        return (
            <Modal
                className="agreement-popup"
                isOpen={this.state.modalOpen}
                style={customStyles}
                onAfterOpen={this.afterOpenModal}
                contentLabel=""
            >

                <p>This IP360 tool is an online repository of field locations and identifies approximate field locations. The tool has been developed for informational purposes only and for your private use. While every effort has been made to ensure the accuracy and reliability of the information and formulas, no guarantee is given or responsibility taken by Ag Connections for the accuracy of the tool or its applicability to your particular circumstances and use. Information in the tool may or may not be timely, correct, or accurate. Ag Connections hereby disclaims any liability for third party websites referenced herein. AG CONNECTIONS PROVIDES THIS IP360 TOOL AND ANY RESULT THEREFORM AS-IS, WHERE-IS, WITH ALL FAULTS AND WITH NO WARRANTY WHATSOEVER, EITHER EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF MERCHANTABILITY AND FITNESS FOR PARTICULAR PURPOSE. YOU ASSUME ANY AND ALL RISKS IN USING THE IP360 TOOL AND RELYING ON THE INFORMATION CONTAINED HEREIN. UNDER NO CIRCUMSTANCES SHALL AG CONNECTIONS BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO LOSS OF PROFIT, LOSS OF BUSINESS, LOSS OF SAVINGS, OR CONSEQUENTIAL OR SPECIAL DAMAGES ARISING OUT OF YOUR ACCESS TO, OR INABILITY TO ACCESS, THIS SITE OR YOUR USE OF, OR RELIANCE UPON, THIS SITE OR THE CONTENT HEREOF, EVEN IF AG CONNECTIONS HAS BEEN NOTIFIED OF SUCH.    </p>
                <div className="agreement-popup-footer">
                    <button className="btn" onClick={this.handleAgreement}>I Agree</button>
                </div>
            </Modal>
        )
    }
};
