import "leaflet.gridlayer.googlemutant";
import "libtess/libtess.min.js";
import "leaflet-pixi-overlay";
import "leaflet-hash/leaflet-hash.js";
import  * as config from '../config/config';
import {
  Graphics,
  Point,
  Polygon,
  RENDERER_TYPE,
  Sprite,
  loaders
} from "pixi.js";
import React, { Component } from "react";
import RBush from 'rbush';

import L from "leaflet";
import _ from "lodash";
import circle from "./img/empty.png";
import { hexToRgb } from "../services/colorSevices";
import { mapLegendControl } from "./mapControls/mapLegend";
import { mapservice } from "../services/mapService";
import { storage } from "../utils/storage";
import supercluster from "supercluster";
import * as browserService from '../services/BrowserService';

const DataUploaded = "data-uploaded";


class Map extends Component {
  state = {
    clustering: true,
    cropsLegend: [],
    dataLoaded: false
  };
  map;
  cropGroups = {};
  features = [];
  index;
  markers;
  ready = false;
  markerLayer;
  polygonLayer;
  markerClusterLayer;
  roadMutant;
  layersControl;
  baseMapLayers;
  markerPolygonLimit = 10;
  prevoiusZoom = null;
  layerGroup = null;
  currentLayer = null;
  loadingLayersPromise;
  loadingLayersPromiseResolved;
  markersDrawn = false;
  polygonsDrawn = false;
  cropGroupIds;
  resources;
  dbConnectionPromise;
  dbConnectionPromiseResolved;
  loadingDataPromise;
  inMemoryCachedMapData = {};
  loader;
  tree = new RBush();

  currentSelectedPoint ={};
  markerOption ={
    icon:L.icon({
      iconUrl: require( "../assets/img/map/marker-icon.png"),
      shadowUrl: require("../assets/img/map/marker-shadow.png"),
      iconSize: [25, 41],
      shadowSize: [50, 64],
      iconAnchor: [12, 41],
      shadowAnchor: [14, 64]
    })
  };
  popupBtnEvents=[];
  currentBrowser = browserService.detectBrowser();


  async componentDidMount() {
    this.loadingLayersPromise = new Promise(
      resolve => (this.loadingLayersPromiseResolved = resolve)
    );

    this.dbConnectionPromise = new Promise(resolve => {
      this.dbConnectionPromiseResolved = resolve;
    });

    this.map = L.map("map", {
      center: [40.1, -92.7],
      zoom: 4,
      minZoom: 2,
      maxZoom: this.props.userMaximumAllowedZoom,
      zoomControl: false,
      attributionControl: false,
    });

    var that = this;
    let markerLayer ;
    let lineLayer;
    let measureModeOn =false;
    let polygonPopup;
    let clickCount=0;

    this.map.on('click', function(e){
      
      //Fix for safair as it fires click twice
      if(that.currentBrowser == 'Safari'){ 
        clickCount++;

        if(clickCount == 2){
          clickCount = 0;
          if(markerLayer){
            markerLayer._popup.openOn(that.map);
          }
          return;
        }
      }

      if(that.polygonLayer._leaflet_id === that.currentLayer._leaflet_id){
        
        //remove any marker or line layer
        markerLayer = that.removeMapLayer(markerLayer);
        lineLayer = that.removeMapLayer(lineLayer);

       
        var point = {x:e.latlng.lng, y:e.latlng.lat};
				var polyFeatures = that.tree.search({
					minX: point.x,
					minY: point.y,
					maxX: point.x,
					maxY: point.y
        });
       
        //click to measure distance then add marker 
        //make sure you open measure mode
        if(measureModeOn){
          markerLayer = that.drawMarker(e.latlng);

          measureModeOn = false;
          
          markerLayer._popup._closeButton.onclick = function(e) {
            markerLayer = that.removeMapLayer(markerLayer);
            lineLayer = that.removeMapLayer(lineLayer);

          };

          //Draw line from Field to selected point
          var lineLatLng=[
            [that.currentSelectedPoint.lat,that.currentSelectedPoint.lng],
            [e.latlng.lat,e.latlng.lng]
          ];
          
          lineLayer= that.drawMapLine(lineLatLng);
        }else{

          //click on polygon
          for (var i = 0; i < polyFeatures.length; i++) {
            var feat = polyFeatures[i].feature;
            if (feat.type === 'Polygon') {
              if (that.containsPoint(feat.coordinates, point)){
                let centerPoint = that.GetCenterPoint(feat);
            
                that.currentSelectedPoint = e.latlng;
                //close any polygonpopup if exists
                if(polygonPopup) polygonPopup._close();

                polygonPopup = L.popup({autoClose:false,"closeOnClick": null, offset: L.point(0, 0)});
                polygonPopup.setContent(`
                  <div class="field_popup">
                    <p class="p-popup"><b>Latitude :</b> ${centerPoint[1]}</p>
                    <p class="p-popup"><b>Longitude :</b> ${centerPoint[0]}</p>
                    <div>
                      <a href="${config.googleConfiguration.googleDirectionLink}${centerPoint[1]},${centerPoint[0]}" target="_blank">
                        <i class="fa fa-map-marker"></i> Get directions
                      </a>
                    </div>
                    <div class="pull-right">
                      <input class="btn-popup measure-dist-btn" type="button" value="Measure distance"/>
                    </div>
                    <div class="clearfix"></div>
                  </div>`
                );

                polygonPopup.setLatLng(e.latlng);
                polygonPopup.openOn(that.map);

                polygonPopup._closeButton.onclick = function(e){
                  
                  markerLayer = that.removeMapLayer(markerLayer);
                  lineLayer = that.removeMapLayer(lineLayer);
                
                }


                return feat;
              }
            }
          }
        }
        

      }
    });

    that.map.on('popupopen',function(popupOpenEventArgs){
      if(popupOpenEventArgs.popup.options.className !== 'marker-popup'){
      
      let measureDistBtns = document.getElementsByClassName("measure-dist-btn");
      
      if(measureDistBtns.length >0){   
          measureDistBtns[measureDistBtns.length-1].addEventListener('click', function(e){
            measureModeOn = true;
          });
      }
    }

    });

    that.map.on('popupclose',function(popupCloseEventArgs){  

      if(popupCloseEventArgs.popup.options.className !== 'marker-popup'){
      
        var measureDistBtns = document.getElementsByClassName("measure-dist-btn");

        if(measureDistBtns.length > 0 ){ 
          for (let i = 0; i < measureDistBtns.length ; i++) {
            let btn = measureDistBtns[i];

            btn.removeEventListener('click',(e)=>{})
            
          }  
        }
      }
    });

    that.map.on('mousemove',function(moveOverEventArgs){
      if(measureModeOn){
        lineLayer =that.removeMapLayer(lineLayer);
        
        let lineLatLng =[
          [that.currentSelectedPoint.lat,that.currentSelectedPoint.lng],
          [moveOverEventArgs.latlng.lat,moveOverEventArgs.latlng.lng]
        ];
      
        lineLayer =  that.drawMapLine(lineLatLng);
      }
    });

    this.roadMutant = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: "roadmap"
    });
    this.roadMutant.addTo(this.map);

    this.props.initializeMap(this.map);
    new L.Control.Zoom({
      position: "bottomright"
    }).addTo(this.map);

    mapLegendControl.addTo(this.map);

    this.AddBaseMapLayers();
    this.updateBaseLayerControl(this.props.userCanHaveLayers);

    this.map.on("zoomend", e => {
      let mapZoomLevel =that.map.getZoom();
      if(mapZoomLevel <= 10){
        markerLayer  = that.removeMapLayer(markerLayer);
        lineLayer = that.removeMapLayer(lineLayer);

        if(polygonPopup){
          that.map.closePopup(polygonPopup);
        }
      }
      this.handleLayers();
    });

    this.map.on("moveend", () => this.update());

    storage.initDBConnection().then(() => {
      this.dbConnectionPromiseResolved();
    });

    this.cropGroupsIds = {};
    if (
      this.props.cropGroups === undefined ||
      this.props.cropGroups.length === 0
    ) {
      // console.log('reloading crop groups in map');
      await this.props.getCropGroups();
    }
    var result = this.props.cropGroups;
    this.cropGroupsIdsColors = {};
    this.cropGroups = {};
    if (result) {
      result.forEach(_ => {
        this.cropGroupsIdsColors[_.id] = { name: _.name, color: _.color, cropIds: [] };
        _.crops.forEach(c => {
          this.cropGroups[c.id] = { name: _.name, color: _.color, group: _.id };
          this.cropGroupsIds[_.id] = 0;
          this.cropGroupsIdsColors[_.id].cropIds.push(c.id);
        });
      });
    }
    this.features = [];
    this.loader = new loaders.Loader();
    this.loader.add("circle", circle);
    this.loader.load(async (loader, resources) => {
      this.resources = resources;
      this.addMarkerClusterLayer();
      this.addMarkerLayer(resources);
      this.addPolygonLayer();
      this.currentLayer = this.markerClusterLayer;
      this.layerGroup = new L.LayerGroup().addTo(this.map);
      this.layerGroup
        .addLayer(this.markerLayer)
        .addLayer(this.polygonLayer)
        .addLayer(this.markerClusterLayer);

      this.loadingLayersPromiseResolved();
      if (this.props.selectedYear) {
        this.loadYearData();
      }
      else {
        this.setState({ dataLoaded: true });
      }
    });
  }

  //Leaflet Function
  removeMapLayer(layer){
    if(layer){
      this.map.removeLayer(layer);
      return undefined;
    }
  }

  drawMapLine(linelatlngArr){

    let drawnLineLayer= L.polyline(
        linelatlngArr,
        {
          color: '#0395DE',
          weight: '2',
          dashArray: '3 , 3',
          dashOffset: '0'}
      ).addTo(this.map);
    
    return drawnLineLayer;
  }


  drawMarker(currentSelectedLatLng){
    let that = this;
    let distance = currentSelectedLatLng.distanceTo(that.currentSelectedPoint);
    let distanceInFeet = Number(distance * 3.2808).toFixed(2);

    let addedMarkerLayer = L.marker(currentSelectedLatLng,that.markerOption).addTo(that.map)
    .bindPopup(`
      <div>
        <p class="p-popup"><b>Distance to selected field :</b> ${distanceInFeet}  <b>feet</b></p>
        <p class="p-popup"><b>Latitude : </b>${currentSelectedLatLng.lat}</p>
        <p class="p-popup"><b>Longitude : </b>${currentSelectedLatLng.lng}</p>
      </div>
    `,{
      className:'marker-popup'
    })
    .openPopup();

    return addedMarkerLayer;
  }

  
  GetCenterPoint(polygon) {
    const coordinates = polygon.coordinates[0];
    return coordinates.reduce(
        (x, y) => [
            x[0] + y[0] / coordinates.length,
            x[1] + y[1] / coordinates.length
        ],
        [0, 0]
    );
  }

  containsPoint(polygon, p) {
    var inside = false,
      part, p1, p2, i, j, k, len, len2;
    // ray casting algorithm for detecting if point is in polygon
    for (i = 0, len = polygon.length; i < len; i++) {
      part = polygon[i];

      for (j = 0, len2 = part.length, k = len2 - 1; j < len2; k = j++) {
        p1 = part[j];
        p2 = part[k];

        if (((p1[1] > p.y) !== (p2[1] > p.y)) && (p.x < (p2[0] - p1[0]) * (p.y - p1[1]) / (p2[1] - p1[1]) + p1[0])) {
          inside = !inside;
        }
      }
    }
    return inside;
  }

  async loadYearData() {
    let auth = this.props.authenticated ? "auth" : "nonauth";

    // Empty the map
    this.setState({ dataLoaded: false });
    this.features = [];
    this.tree.clear();
    this.setState({ cropsLegend: {} });
    this.props.setSelectedCropGroups([]);
    this.props.setYearCropGroups([]);
    await this.drawFeatures();

    //Get Data either from memory in the component or by call map service
    let updatedYearData = this.inMemoryCachedMapData[
      `${auth}_${this.props.selectedYear}`
    ];
    if (!updatedYearData) {
      await this.dbConnectionPromise;

      if (this.loadingDataPromise) {
        await this.loadingDataPromise;
      }
      this.loadingDataPromise = mapservice.getData(
        this.props.selectedYear,
        this.props.authenticated
      );
      updatedYearData = await this.loadingDataPromise;
      this.inMemoryCachedMapData[
        `${auth}_${this.props.selectedYear}`
      ] = updatedYearData;
    }

    // Draw retrieved data on the map
    const mapData = updatedYearData;
    this.features = mapData.points;

    let usedCropGroups = {};
    _.uniqBy(this.features, item => item.properties.cropId).forEach(
      item =>
        (usedCropGroups[
          this.cropGroups[item.properties.cropId].name
        ] = this.cropGroups[item.properties.cropId].color)
    );
    var cropNames = [...Object.keys(usedCropGroups).sort()];
    this.props.setYearCropGroups(cropNames);
    let legend = Object.keys(usedCropGroups)
      .map(item => {
        return {
          name: item,
          color: usedCropGroups[item]
        };
      })
      .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
    this.setState({ cropsLegend: legend });
    this.props.setSelectedCropGroups(cropNames);

    await this.drawFeatures();
    this.setState({ dataLoaded: true });
  }

  async updateCropsVisibility() {
    //this.setState({ dataLoaded: false });
    await this.drawFeatures();
    //this.setState({ dataLoaded: true });
  }

  async drawFeatures() {
    await this.loadingLayersPromise;
    this.ready = false;
    this.index = new supercluster({
      log: false,
      radius: 48,
      extent: 256,
      minZoom: 2,
      maxZoom: this.markerPolygonLimit,
      reduce: (accumulated, props) => {
        if (props.category_count) {
          for (let c in props.category_count)
            accumulated.category_count[c] += props.category_count[c];
        } else accumulated.category_count[props.cropGroupId]++;
      },
      initial: () => ({
        category_count: { ...this.cropGroupsIds }
      })
    }).load(this.features);
    this.ready = true;
    this.markersDrawn = false;
    this.polygonsDrawn = false;
    this.markerLayer._pixiContainer.removeChildren();
    this.polygonLayer.utils.getRenderer().clear();
    this.polygonLayer.utils.getContainer().clear();
    this.markerLayer._drawCallback(this.markerLayer.utils);
    this.polygonLayer._drawCallback(this.polygonLayer.utils);
    this.update();
  }

  update() {
    if (!this.ready) return;
    var bounds = this.map.getBounds();
    var bbox = [
      bounds.getWest(),
      bounds.getSouth(),
      bounds.getEast(),
      bounds.getNorth()
    ];
    var zoom = this.map.getZoom();
    var clusters = this.index.getClusters(bbox, zoom);
    var filteredData = [];
    var selectedIds = _.keys(
      _.pickBy(this.cropGroupsIdsColors, item => {
        return _.includes(this.props.selectedCropGroups, item.name);
      })
    );
    for (var cluster of clusters) {
      var newCluster = { ...cluster };
      if (cluster.properties.cluster) {
        newCluster.properties.category_count = _.pick(
          newCluster.properties.category_count,
          selectedIds
        );
        newCluster.properties.point_count = _.sum(
          _.values(newCluster.properties.category_count)
        );
        if (newCluster.properties.point_count) {
          var count = newCluster.properties.point_count;
          newCluster.properties.point_count_abbreviated =
            count >= 10000
              ? `${Math.round(count / 1000)}k`
              : count >= 1000
                ? `${Math.round(count / 100) / 10}k`
                : count;
          filteredData.push(newCluster);
        }
      } else {
        let selectedCrops = [];
        selectedIds.forEach((id) => {
          selectedCrops.push(...this.cropGroupsIdsColors[id].cropIds);
        })
        if (_.includes(selectedCrops, cluster.properties.cropId))
          filteredData.push(newCluster);
      }
    }
    this.markerClusterLayer.clearLayers();
    this.markerClusterLayer.addData(filteredData);
    filteredData.length = 0;
    clusters = null;
  }

  clusterIcon = L.Icon.extend({
    options: {
      iconSize: new L.Point(48, 48)
    },
    createIcon: function () {
      var e = document.createElement("canvas");
      this._setIconStyles(e, "icon");
      var s = this.options.iconSize;
      e.width = s.x;
      e.height = s.y;
      this.draw(e.getContext("2d"));
      return e;
    },
    draw: function (canvas) {
      const { category_count, count, label, cropGroups } = this.options;
      var pi2 = Math.PI * 2;
      var start = 0;
      for (var i in category_count) {
        var size = category_count[i] / count;

        if (size > 0) {
          var offset = 0.14;
          if (size === 1) offset = 0;
          canvas.beginPath();
          canvas.moveTo(22, 22);
          canvas.fillStyle = cropGroups[i].color;
          var from = start + offset,
            to = start + size * pi2;

          if (to < from) {
            from = start;
          }
          canvas.arc(22, 22, 22, from, to);

          start = start + size * pi2;
          canvas.lineTo(22, 22);
          canvas.fill();
          canvas.closePath();
        }
      }

      canvas.beginPath();
      canvas.fillStyle = "white";
      canvas.arc(22, 22, 18, 0, Math.PI * 2);
      canvas.fill();
      canvas.closePath();

      canvas.fillStyle = "#555";
      canvas.textAlign = "center";
      canvas.textBaseline = "middle";
      canvas.font = "bold 12px sans-serif";

      canvas.fillText(label, 22, 22, 40);
    }
  });

  createClusterIcon = (feature, layer) => {
    let count, category_count, label;
    if (feature.properties.cluster) {
      count = feature.properties.point_count;
      category_count = feature.properties.category_count;
      label = feature.properties.point_count_abbreviated;
    } else {
      count = 1;
      category_count = { [this.cropGroups[feature.properties.cropId].group]: 1 };
      label = "1";
    }
    layer.setIcon(
      new this.clusterIcon({
        category_count,
        count,
        label,
        cropGroups: this.cropGroupsIdsColors
      })
    );
  };

  addMarkerLayer(resources) {
    this.markerLayer = (() => {
      var prevZoom = null;
      var markerSprites = [];

      var pixiContainer = new Graphics();
      pixiContainer.visible = false;
      var doubleBuffering =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      return L.pixiOverlay(
        utils => {
          var container = utils.getContainer();
          var renderer = utils.getRenderer();
          var project = utils.latLngToLayerPoint;
          var currentZoom = this.map.getZoom();
          var scale = utils.getScale();
          var invScale = 1 / scale;

          if (!this.markersDrawn) {
            this.features.forEach(feature => {
              if (
                this.cropGroups[feature.properties.cropId] &&
                feature.geometry &&
                feature.geometry.type === "Point" &&
                this.props.selectedCropGroups.indexOf(
                  this.cropGroups[feature.properties.cropId].name
                ) > -1
              ) {
                var rgbColor = hexToRgb(
                  this.cropGroups[feature.properties.cropId].color
                );
                var markerSprite = new Sprite(resources.circle.texture);
                var coords = project([
                  feature.geometry.coordinates[1],
                  feature.geometry.coordinates[0]
                ]);
                markerSprite.x = coords.x;
                markerSprite.y = coords.y;
                markerSprite.anchor.set(0.5, 0.5);
                markerSprite.tint =
                  256 * (rgbColor.r * 256 + rgbColor.g) + rgbColor.b;
                markerSprite.scale.set(invScale / 3);
                container.addChild(markerSprite);
                markerSprites.push(markerSprite);
              }
            });
          }
          this.markersDrawn = true;
          if (this.markersDrawn && prevZoom !== currentZoom) {
            markerSprites.forEach(markerSprite => {
              markerSprite.scale.set(invScale / 3);
            });
          }
          prevZoom = currentZoom;
          renderer.render(container);
        },
        pixiContainer,
        {
          doubleBuffering: doubleBuffering,
          destroyInteractionManager: true
        }
      );
    })();
  }

  addPolygonLayer() {
    this.polygonLayer = (() => {
      var pixiContainer = new Graphics();
      pixiContainer.visible = false;
      var doubleBuffering =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      return L.pixiOverlay(
        utils => {
          var container = utils.getContainer();
          var renderer = utils.getRenderer();
          var gl = renderer.gl;
          var project = utils.latLngToLayerPoint;

          if (!this.polygonsDrawn) {
            if (renderer.type === RENDERER_TYPE.WEBGL) {
              gl.blendFunc(gl.ONE, gl.ZERO);
            }

            let drawPoly = (color, alpha) => {
              return poly => {
                var shape = new Polygon(
                  poly[0].map(function (point) {
                    return new Point(point[0], point[1]);
                  })
                );
                //container.lineStyle(0.05, this.lightenDarkenColor(color, 5), alpha, 0, true);
                container.beginFill(color, alpha);
                container.drawShape(shape);
                if (poly.length > 1) {
                  for (var i = 1; i < poly.length; i++) {
                    var hole = new Polygon(
                      poly[i].map(function (point) {
                        return new Point(point[0], point[1]);
                      })
                    );
                    container.drawShape(hole);
                    container.addHole();
                  }
                }
              };
            };

            this.features.forEach(feature => {
              if (
                this.cropGroups[feature.properties.cropId] &&
                this.props.selectedCropGroups.indexOf(
                  this.cropGroups[feature.properties.cropId].name
                ) > -1
              ) {
                var color = this.cropGroups[
                  feature.properties.cropId
                ].color.replace("#", "0x");
                var alpha = 0.5;
                var polygonGeometry = feature.properties.geometry;
                if (polygonGeometry && polygonGeometry.type === "Polygon") {
                  let coordCopy = JSON.parse(
                    JSON.stringify(polygonGeometry.coordinates)
                  );
                  coordCopy[0].forEach(position => {
                    var proj = project([position[1], position[0]]);
                    position[0] = proj.x;
                    position[1] = proj.y;
                  });
                  let bounds = L.bounds(polygonGeometry.coordinates[0]);
                  this.tree.insert({
                    minX: bounds.min.x,
                    minY: bounds.min.y,
                    maxX: bounds.max.x,
                    maxY: bounds.max.y,
                    feature: polygonGeometry
                  });
                  drawPoly(color, alpha)(coordCopy);
                }
              }
            });
          }
          this.polygonsDrawn = true;
          renderer.render(container);
        },
        pixiContainer,
        {
          doubleBuffering: doubleBuffering,
          destroyInteractionManager: true
        }
      );
    })();
  }

  addMarkerClusterLayer() {
    this.markerClusterLayer = L.geoJson(null, {
      onEachFeature: this.createClusterIcon,
      style: feature => {
        if (!feature.properties.cluster) {
          return {
            color: this.cropGroupsIdsColors[feature.properties.cropGroupId]
              .color,
            weight: 1
          };
        }
      }
    });
  }

  AddBaseMapLayers() {
    var satMutant = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: "hybrid"
    });

    var terrainMutant = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: "terrain"
    });

    this.layersControl = L.control.layers(
      {
        Map: this.roadMutant,
        Terrain: terrainMutant,
        Satellite: satMutant
      },
      {},
      {
        collapsed: true
      }
    );
  }

  updateBaseLayerControl(userCanHaveLayers) {
    if (userCanHaveLayers && !this.layersControl._map)
      this.layersControl.addTo(this.map);
    else if (!userCanHaveLayers && this.layersControl._map)
      this.layersControl.remove();
  }

  updateInMemoryCache(year) {
    let auth = this.props.authenticated ? "auth" : "nonauth";
    delete this.inMemoryCachedMapData[`${auth}_${year}`];
  }

  componentDidUpdate(prevProp) {
    if (prevProp.userMaximumAllowedZoom !== this.props.userMaximumAllowedZoom) {
      this.map.setMaxZoom(this.props.userMaximumAllowedZoom);
    }
    if (prevProp.userCanHaveLayers !== this.props.userCanHaveLayers) {
      this.updateBaseLayerControl(this.props.userCanHaveLayers);
    }
    if (this.props.authenticated !== prevProp.authenticated) {
      this.handleLayers();
    }
    if (
      this.props.selectedCropGroups.length !==
      prevProp.selectedCropGroups.length
    ) {
      this.updateCropsVisibility();
    }

    if (this.props.uploads.length !== prevProp.uploads.length) {
      let lastYear = this.props.uploads[this.props.uploads.length - 1];
      this.updateInMemoryCache(lastYear);
      if (lastYear === this.props.selectedYear) {
        this.loadYearData();
      }
    }

    if (
      (this.props.selectedYear &&
        this.props.authenticated !== prevProp.authenticated) ||
      (this.props.selectedYear &&
        this.props.selectedYear !== prevProp.selectedYear)
    ) {
      this.loadYearData();
    }
  }

  async handleLayers() {
    await this.loadingLayersPromise;
    if (
      !this.props.authenticated ||
      this.map.getZoom() <= this.markerPolygonLimit
    ) {
      if (this.state.clustering) {
        this.changeCurrentLayer(this.markerClusterLayer);
      } else {
        this.changeCurrentLayer(this.markerLayer);
      }
    } else {
      this.changeCurrentLayer(this.polygonLayer);
    }
  }

  changePixiLayerVisibility(layer, visible) {
    layer._pixiContainer.visible = visible;
    layer.utils.getRenderer().render(layer.utils.getContainer());
  }

  changeCurrentLayer(newLayer) {
    if (newLayer._leaflet_id !== this.currentLayer._leaflet_id) {
      if (newLayer._pixiContainer) {
        this.changePixiLayerVisibility(newLayer, true);
      } else {
        this.layerGroup.addLayer(newLayer);
      }
      if (this.currentLayer._pixiContainer) {
        this.changePixiLayerVisibility(this.currentLayer, false);
      } else {
        this.layerGroup.removeLayer(this.currentLayer);
      }
      this.currentLayer = newLayer;
    }
  }

  handleClusteringChange = e => {
    this.setState({ clustering: e.target.checked }, () => this.handleLayers());
  };

  componentWillUnmount() {
    this.markerLayer.remove();
    this.polygonLayer.remove();
    this.loader.reset();
    this.loader.destroy();
    this.setState({ cropsLegend: [] });
    this.map.removeLayer(this.markerClusterLayer);
    this.map.removeLayer(this.layerGroup);
    this.map.remove();
    this.map.off();
    this.map.off("moveend");
    this.map.off("zoomend");
    this.map = null;
    this.index = null;
    this.markerLayer = null;
    this.polygonLayer = null;
    this.markerClusterLayer = null;
    this.layerGroup = null;
    this.features.length = 0;
    this.cropGroupsIds = {};
    this.cropGroups = {};
    this.inMemoryCachedMapData = {};
  }

  render() {
    return (
      <div>
        <div className={this.state.dataLoaded ? "loader" : `loader visible`}>
          <div className="loader-content">
            <div className="loader-img" />
            <label>Loading...</label>
          </div>
        </div>
        <div className="cartes map" id="map">
          <div className="legend geometry top center hide">
            <div className="wrapper">
              <div className="content" />
            </div>
          </div>
        </div>
        <div className="custom-control custom-checkbox use-clusters">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={this.state.clustering}
            onChange={this.handleClusteringChange}
          />
          <label className="custom-control-label">Use Clusters</label>
        </div>

        <div className="custom-control-legend">
          <div className="main-legend">
            {this.state.cropsLegend.length > 0 && (
              <ul>
                {this.state.cropsLegend.map(item => (
                  <li key={item.name}>
                    <span
                      className="color"
                      style={{ backgroundColor: item.color }}
                    />
                    <span className="crop">{item.name}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Map;