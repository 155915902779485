import React from "react";
import Select from "react-select";
import Combobox from "react-widgets/lib/Combobox";
import "react-widgets/dist/css/react-widgets.css";

const SelectGroup = ({
  groups,
  handleChange,
  handleInputChange,
  error,
  type,
  value,
  ...rest
}) => {
  const currentValue = groups
    ? groups.find(group => group.value === value)
    : "";
  // console.log("groups: " + JSON.stringify(groups));
  if (type === "year") {
    return (
      <div className="form-group">
        <Select
          style={{ borderRadius: 0 }}
          isSearchable={false}
          options={groups}
          onChange={handleChange}
          error={error}
          placeholder="Crop Year"
          value={currentValue || ""}
        />
        {error && (
          <div className="alert alert-danger">Crop Year is required</div>
        )}
      </div>
    );
  }

  if (type === "partner") {
    // console.log("currentvalue: " + JSON.stringify(currentValue));
    return (
      <div className="form-group">
        <Select
          isSearchable={false}
          options={groups}
          onChange={handleChange}
          error={error}
          placeholder="Partner"
          value={currentValue || ""}
        />
        {error && <div className="alert alert-danger">Partner is required</div>}
      </div>
    );
  }

  if (type === "partnerCombo") {
    return (
      <div className="form-group">
        <Combobox
          data={groups}
          onChange={handleChange}
          suggest={true}
          filter="startsWith"
          placeholder="Partner"
          value={value || ""}
        />
        {error && <div className="alert alert-danger">Partner is required</div>}
      </div>
    );
  }

  let select_options = [];
  if (groups !== undefined) {
    groups.forEach(group => {
      //incase it has one element then display it without header
      if (group.options.length > 0)
        select_options.push({
          //Add the group name
          label: group.name,
          color: group.color,
          value: group.options[0].id,
          name: "crop"
        });
    });
  }
  const cropCurrentValue = select_options
    ? select_options.find(select_option => select_option.value === value)
    : "";
  return (
    <div className="form-group">
      <Select
        isSearchable={false}
        options={select_options}
        // styles={customStyles}
        onChange={handleChange}
        error={error}
        // formatGroupLabel={formatGroupLabel}
        placeholder="Crop"
        value={cropCurrentValue || ""}
      />
      {error && <div className="alert alert-danger">Crop is required</div>}
    </div>
  );
};

export default SelectGroup;

// const customStyles = {
//   label: (provided, state) => {
//     return {
//       ...provided,
//       paddingLeft: 5,
//       color: "red"
//     };
//   },
//   option: (provided, state) => {
//     //console.log(state);
//     return {
//       ...provided,
//       paddingLeft: state.isDisabled ? 5 : 20
//     };
//   }
// };

/*
       <select
        className="form-control css-10nd86i"
        onChange={handleChange}
        placeholder="Crop"
        name="crop"
        value={value}
        {...rest}
      >
        <option value="" disabled>
          Crop
        </option>
        {groups !== undefined
          ? groups.map(group => {
            if (group.options.length === 1)
              return (
                <option key={group.options[0].id} value={group.options[0].id}>
                  {group.name}
                </option>
              );
            else if (group.options.length > 0)
              return (
                <optgroup key={group.name} label={group.name}>
                  {group.options.map(option => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
                </optgroup>
              );
            else return null;
          })
          : null}
      </select> */
//else then display header and it's options
// else
//   group.options.map(option => {
//     return select_options.push({
//       //Add the group name
//       label: `${group.name}: ${option.name}`,
//       color: group.color,
//       value: option.id,
//       name: "crop"
//     });
//   });

// select_options.push({
//     //Add the group header
//     label: group.name,
//     color: group.color,
//     //Add the options for each group header
//     options: group.options.map(option => {
//       return {
//         label: option.name,
//         value: option.id,
//         group: group.name,
//         name: "crop"
//       };
//     })
//   });
// const formatGroupLabel = select_options => (
//   <div>
//     <span
//       style={{
//         // color: select_options.color,
//         fontSize: 11,
//         fontWeight: 700
//         // textShadow: "#ccc 3px 0 10px"
//       }}
//     >
//       {select_options.label}
//     </span>
//   </div>
// );
