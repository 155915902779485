import React, { Component } from "react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
// import LineStyles from "./lineStyles";
import "./lines.css";

class CropsTables extends Component {
  state = {
    cropGroups: [],
    RenderedCropGroup: [],
    modifiedCropGroups: [],
    API_res: false
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cropGroups !== this.props.cropGroups && !this.state.API_res) {
      this.setState({
        cropGroups: this.props.cropGroups.map(a => ({ ...a })),
        RenderedCropGroup: this.props.cropGroups.map(a => ({ ...a })),
        API_res: true
      });
    } else if (
      this.props.modifiedCrops.length === 0 &&
      prevProps.modifiedCrops.length > 0 &&
      prevProps.reset &&
      !this.props.saved
    ) {
      const group = this.state.cropGroups.map(a => ({ ...a }));
      this.setState({
        modifiedCropGroups: [],
        RenderedCropGroup: group
      });
    }
  }

  handleClick = (crop, type) => {
    const { RenderedCropGroup } = this.state;
    const index = RenderedCropGroup.indexOf(crop);
    if (index > -1) {
      if (type === "color")
        RenderedCropGroup[index].displayColorPicker = !RenderedCropGroup[index]
          .displayColorPicker;
      else
        RenderedCropGroup[index].displayLineStyles = !RenderedCropGroup[index]
          .displayLineStyles;

      this.setState({ RenderedCropGroup });
    }
  };

  handleClose = crop => {
    const { RenderedCropGroup } = this.state;
    const index = RenderedCropGroup.indexOf(crop);
    if (index > -1) {
      RenderedCropGroup[index].displayColorPicker = false;
      this.setState({ RenderedCropGroup });
    }
  };

  handleChange = (color, crop, type) => {
    const { RenderedCropGroup, modifiedCropGroups } = this.state;

    const index = RenderedCropGroup.indexOf(crop);
    if (index > -1) {
      if (type === "color") RenderedCropGroup[index].color = color.hex;

      let modifiedCrop = modifiedCropGroups.filter(
        group => group.id === crop.id
      );

      const ModifiedIndex = modifiedCropGroups.indexOf(modifiedCrop[0]);
      if (ModifiedIndex > -1) modifiedCropGroups.splice(ModifiedIndex, 1);
      modifiedCropGroups.push(crop);
      this.props.onChange(modifiedCropGroups.map(a => ({ ...a })));
      modifiedCrop = [];
      this.setState({ RenderedCropGroup, modifiedCropGroups });
    }
  };

  render() {
    const { RenderedCropGroup } = this.state;

    return (
      <div className="table-responsive">
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Crop</th>
            <th className="text-center">Color</th>
            {/* <th className="text-center">Line Style</th> */}
          </tr>
        </thead>

        <tbody>
          {RenderedCropGroup.map(crop => {
            const styles = reactCSS({
              default: {
                color: {
                  width: "45px",
                  height: "18px",
                  borderRadius: "2px",
                  background: `${crop.color}`
                },
                swatch: {
                  padding: "3px",
                  background: "#fff",
                  borderRadius: "1px",
                  boxShadow: "0 0 0 1px rgba(0,0,0,.2)",
                  display: "inline-block",
                  cursor: "pointer",
                  marginTop: "3px"
                },
                popover: {
                  position: "absolute",
                  zIndex: "2",
                  bottom: "-90px",
                  right: "62%"
                },
                cover: {
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px"
                }
              }
            });

            return (
              <tr key={crop.id}>
                <td>{crop.name}</td>
                <td className="text-center">
                  <div
                    style={styles.swatch}
                    onClick={() => this.handleClick(crop, "color")}
                  >
                    <div style={styles.color} />
                  </div>
                  {crop.displayColorPicker ? (
                    <div style={styles.popover}>
                      <div
                        style={styles.cover}
                        onClick={() => this.handleClose(crop, "color")}
                      />
                      <SketchPicker
                        color={crop.color}
                        onChange={color =>
                          this.handleChange(color, crop, "color")
                        }
                      />
                    </div>
                  ) : null}
                </td>
                {/* 
                <td className="text-center">
                  <hr
                    style={{
                      border: `2px ${crop.lineStyle} black`,
                      width: 70,
                      cursor: "pointer"
                    }}
                    onClick={() => this.handleClick(crop, "line")}
                  />
                  {crop.displayLineStyles ? <LineStyles /> : null}
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
    );
  }
}

export default CropsTables;
