import axios from "axios";
import { toast } from 'react-toastify';
// import _ from "lodash";
// var querystring = require("querystring");
//Configuration of the http request
let axiosConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
    // "Access-Control-Allow-Origin": "*"
  }
};

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401)
    toast.error("Session expired");
  if (error.response.status === 403)
    toast.error("You don't have permission to do this action");
  if(error.response.status === 429)
    toast.error("Too Many Requests.please try again later.");

  return Promise.reject(error);
});


export const GetConfirguration = () => {
  return axiosConfig;
};

export const SetAuthorizationHeader = accessToken => {
  if (accessToken) {
    axiosConfig.headers["authorization"] = `Bearer ${accessToken}`;
  } else {
    delete axiosConfig.headers["authorization"];
  }
};

//Post method for saving features of geojson inside database
export const HttpPost_cropSpeciality = async postObject => {
  return await axios
    .post("/crop-specialities", postObject, axiosConfig)
    .then(res => {
      //console.log("RESPONSE RECEIVED: ", res);
      return res;
    })
    .catch(err => {
      //console.log("AXIOS ERROR: ", err);
      return undefined;
    });
};

//Get method for getting features of geojson inside database
export const HttpGet_cropSpeciality = async () => {
  return await axios
    .get("/crop-specialities?year=2018", axiosConfig)
    .then(({ data }) => {
      //console.log("RESPONSE RECEIVED: ", res);
      return data;
    })
    .catch(err => {
      //console.log("AXIOS ERROR: ", err);
      return undefined;
    });
};

//Get method to retrieve the crop groups from database
export const HttpGet_cropGroup = async () => {
  return await axios
    .get("/crop-groups", axiosConfig)
    .then(({ data }) => {
      // console.log("RESPONSE RECEIVED: ", data);
      data.sort((a, b) => {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      return data;
    })
    .catch(err => {
      console.log("AXIOS ERROR: ", err);
    });
};

//Put method to Save the crop groups in database
export const HttpPut_cropGroup = async postObject => {
  return await axios
    .put(`/crop-groups`, postObject, axiosConfig)
    .then(res => {
      // console.log("RESPONSE RECEIVED: ", res);
      return res;
    })
    .catch(err => {
      console.log("AXIOS ERROR: ", err);
      return undefined;
    });
};

export const HttpGet_uploadJob = async QueryString => {
  /*querystring.stringify({
        searchCriterias: JSON.stringify(QueryString) */
  let query = "";
  Object.keys(QueryString).forEach(prop => {
    query += `${prop}=${QueryString[prop]}&`;
  });
  query = query.slice(0, query.length - 1);
  // console.log(query);
  return await axios
    .get(`/upload-job?${query}`, axiosConfig)
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log("AXIOS ERROR: ", err);
      return undefined;
    });
};

export const HttpGet_uploadJob_partners = async QueryString => {
  // console.log("query", QueryString);
  return await axios
    .get(`/upload-job/partners`, axiosConfig)
    .then(res => {
      // console.log("RESPONSE RECEIVED: ", res);
      return res;
    })
    .catch(err => {
      console.log("AXIOS ERROR: ", err);
      return undefined;
    });
};

//delete method for Deleting Polygons from crop-specialities, Upload_job by crop-id, crop-year, and partner and update
export const HttpDelete_uploadJob = async postObject => {
  return await axios
    .post(`/upload-job`, postObject, axiosConfig)
    .then(res => {
      // console.log("RESPONSE RECEIVED: ", res);
      return res;
    })
    .catch(err => {
      console.log("AXIOS ERROR: ", err);
      return undefined;
    });
};

export const HttpGet_years = () => {
  return axios
    .get("/crop-specialities/years", axiosConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
    });
};

export const HttpGet_allYears = () => {
  return axios
    .get("/crop-specialities/allYears", axiosConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
    });
};


export const HttpGet_cropYearsSettings = async () => {
  return await axios
    .get("/settings/crop-years", axiosConfig)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log("ERROR: ", err);
    });
};

export const HttpPut_cropYearsSettings = async postObject => {
  return await axios
    .put(`/settings/crop-years`, postObject, axiosConfig)
    .then(res => {
      return res;
    });
};

export const HttpGet_users = async () => {
  return await axios
    .get("/users", axiosConfig)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log("ERROR: ", err);
      throw (err);
    });
};

export const HttpPost_sendInvitation = async postObject => {
  return await axios
    .post("/users/send-invitation", postObject, axiosConfig)
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log("ERROR: ", err);
      throw (err.response.data && err.response.status == 400 ? err.response.data : "An error occurred");
    });
};

export const HttpPost_deleteUser = async postObject => {
  return await axios
    .post("/users/delete", postObject, axiosConfig)
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log("ERROR: ", err);
      throw (err.response.data && err.response.status == 400 ? err.response.data : "An error occurred");
    });
};

export const HttpPost_updateUserLoginDate = async (email, name, isAdmin) => {
  let names = name.split(" ");
  let role = isAdmin ? "Admin" : "Partner";
  return await axios
    .post("/users/update-login-date", {
      email,
      firstName: names[0],
      lastName: names[1],
      role
    }, axiosConfig)
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log("ERROR: ", err);
      throw (err);
    });
};