
window._db = null;

export var storage = {
    // var _db = null;
    initDBConnection: async function () {
        var indexedDB = window.indexedDB;

        if (!indexedDB)
            return;

        var dbName = 'ip360',
            dbVersion = '1',
            request = indexedDB.open(dbName, dbVersion);

        request.onerror = function (e) {
            console.log("error: ", e);
        };
        request.onupgradeneeded = function (e) {
            var objectStore = e.currentTarget.result.createObjectStore(
                "map", { keyPath: "key" });
        };

        var temp = await new Promise(function (resolve, reject) {
            request.onsuccess = function (e) {
                window._db = request.result;
                //console.log("success: " + dbName);
                resolve();
            };
        });

        return temp;

    },
    set: function (objStoreName, key, value) {
        var transaction = window._db.transaction([objStoreName], 'readwrite');
        var objectStore = transaction.objectStore(objStoreName);
        objectStore.put({ key: key, value: value });
    },
    remove: function (objStoreName, key) {
        var transaction = window._db.transaction([objStoreName], 'readwrite');
        var objectStore = transaction.objectStore(objStoreName);
        objectStore.delete(key);
    },
    get: function (objStoreName, key, successCallback, errorCallback) {
        var transaction = window._db.transaction([objStoreName], 'readonly');
        var objectStore = transaction.objectStore(objStoreName);
        var result = objectStore.get(key);
        return new Promise(function (resolve, reject) {
            result.onsuccess = function (event) {
                resolve(event.target.result ? event.target.result.value : undefined);
            };
        });
        // result.onsuccess = (event) => {
        //     console.log(event.target);
        //     // console.log(event.target.result.name);
        //     successCallback(event.target.result ? event.target.result.value : undefined);
        // };
        // result.onerror = errorCallback;
    }
};